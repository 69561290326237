import { createContext, useContext } from 'react'
import { makeAutoObservable } from 'mobx'
import { VehicleSpecification } from '../models/VehicleSpecification'
import { StoreInstances } from '../StoreInstancesContainer'
import CatalogServiceProvider from 'src/services/CatalogServiceProvider'

export const VehicleSpecificationContext = createContext<
  VehicleSpecificationStore | undefined
>(undefined)

export const useVehicleSpecification = (): VehicleSpecificationStore => {
  const vehicleSpecification = useContext(VehicleSpecificationContext)
  if (!vehicleSpecification) {
    throw new Error(
      'No VehicleSpecificationContext.Provider found when calling useVehicleSpecification.'
    )
  }
  return vehicleSpecification
}

export class VehicleSpecificationStore {
  vehicleSpecifications: VehicleSpecification[] = []

  loadingVehicleSpecification = false

  showVehicleSpecificationDrawer = false

  constructor() {
    makeAutoObservable(this)
  }

  public setShowVehicleSpecificationDrawer = (show: boolean): void => {
    this.showVehicleSpecificationDrawer = show
  }

  public fetchVehicleSpecification = async (): Promise<void> => {
    this.loadingVehicleSpecification = true
    const { currentVehicle } = StoreInstances.searchStore

    try {
      const resp =
        await CatalogServiceProvider.fetchVehicleSpecifications(currentVehicle)
      if (resp.status === 200) {
        this.vehicleSpecifications = resp.data.map((vehicleSpec) => {
          return {
            ...vehicleSpec,
            specificationItems: vehicleSpec.specificationItems.map(
              (item, specItemIndex) => {
                return {
                  ...item,
                  hash:
                    item.partNumber +
                    item.years +
                    item.description +
                    specItemIndex,
                }
              }
            ),
          }
        })
      } else {
        this.vehicleSpecifications = []
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      this.vehicleSpecifications = []
    }
    this.loadingVehicleSpecification = false
  }
}
