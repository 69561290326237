import { ReactElement, useEffect, useState } from 'react'
import {
  Size,
  desktopMultipleLines,
  mobileMultipleLines,
  useWindowSize,
} from 'src/hooks'
import { I18n } from 'src/i18n'
import UserDataServiceProvider from 'src/services/UserDataServiceProvider'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { Sanitized } from '../search/Results/utils/Sanitized'
import { observer } from 'mobx-react-lite'
import { StoreInstances } from 'src/store/StoreInstancesContainer'

const NUM_OF_SPACE_BETWEEN_MESSAGES = 1
const spaceSeparator = '&nbsp;'.repeat(NUM_OF_SPACE_BETWEEN_MESSAGES)

export const BannerMessage = observer((): ReactElement => {
  const [bannerMessageString, setBannerMessageString] = useState('')

  const size: Size = useWindowSize()

  const [charactersLength, setcharactersLength] = useState(desktopMultipleLines)

  const [showAnchor, setShowAnchor] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const { bannerMessage, setBannerMessage } = StoreInstances.uiStore
  const [messages, setMessages] = useState([''])

  useEffect(() => {
    UserDataServiceProvider.getMessages().then((response) => {
      setMessages(response?.messages)
      setBannerMessageString(
        messages && messages.length ? messages.join(spaceSeparator) : ''
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((size?.width ?? 0) < breakpoints.mobileLarge) {
      setcharactersLength(mobileMultipleLines)
    } else {
      setcharactersLength(desktopMultipleLines)
    }

    if (bannerMessageString.length > charactersLength) {
      const substring = bannerMessageString.substring(0, charactersLength - 3) // minus three because of the ellipsis ...
      setBannerMessage(`${substring}...`)
      setShowAnchor(true)
    }
  }, [bannerMessageString, charactersLength, setBannerMessage, size?.width])

  useEffect(() => {
    if (!isExpanded && bannerMessageString.length > charactersLength) {
      const substring = bannerMessageString.substring(0, charactersLength - 3) // minus three because of the ellipsis ...
      setBannerMessage(`${substring}...`)
    } else {
      setBannerMessage(
        bannerMessageString.substring(0, bannerMessageString.length)
      )
    }
  }, [bannerMessageString, charactersLength, isExpanded, setBannerMessage])

  useEffect(() => {
    if (isExpanded) setBannerMessageString(messages.join('<br/> '))
    else
      setBannerMessageString(
        messages && messages.length ? messages.join(spaceSeparator) : ''
      )
  }, [isExpanded, messages])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  if (!bannerMessage) return null
  return (
    <Container>
      <ScrollTextContainer>
        <StyledScrollText>
          <Marquee textClick={isExpanded}>
            <Sanitized html={bannerMessage} />
            {showAnchor && (
              <StyledTextWrapper
                onClick={() => {
                  setIsExpanded(!isExpanded)
                }}
              />
            )}
          </Marquee>
        </StyledScrollText>
      </ScrollTextContainer>
      <SeeMoreText onClick={toggleExpand}>
        <I18n
          name={!isExpanded ? 'seeMore' : 'seeLess'}
          style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
        />
      </SeeMoreText>
    </Container>
  )
})

interface MarqueeOptions {
  textClick?: boolean
}

const Container = styled.div`
  width: 100%;
  font-family: Roboto;
  text-size: 12px;
  background-color: ${defaultTheme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 38px;
  padding: 8px 16px;
  gap: 16px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    overflow: visible;
  }
  @media print {
    display: none;
  }
`

const ScrollTextContainer = styled.div`
  width: 90%;
  overflow: hidden;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-grow: 1;
`

const SeeMoreText = styled.div`
  margin-bottom: auto;
  flex-shrink: 1;
  text-wrap: nowrap;
`

const StyledTextWrapper = styled.span`
  overflow: hidden;
`

const Marquee = styled.div<MarqueeOptions>`
  ${(p) =>
    p.textClick
      ? `-webkit-animation: none;
  white-space: normal;`
      : `
  
  -moz-animation: marquee 20s linear infinite;
  -webkit-animation: marquee 20s linear infinite;
  animation: marquee 30s linear infinite;
  @-moz-keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @-webkit-keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes marquee {
    0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
  `}
`

const StyledScrollText = styled.span`
  white-space: nowrap;
  cursor: pointer;
  text-align: left;
`
