import { ReactElement, useMemo } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { breakpoints } from 'src/theme/breakpoints'
import { useMediaQuery } from '@mui/material'
import { useUserStore } from 'src/store/user/UserStore'
import { useBannerPromotionStore } from 'src/store/promotion/BannerPromotionStore'

export const BannerAdsHeader = (): ReactElement | null => {
  const history = useHistory()
  const pathNaname = history.location.pathname
  const userStore = useUserStore()
  const { isGeneralAdsEnabled } = useBannerPromotionStore()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.laptop}px)`)
  const getZoneId = useMemo(() => {
    if (import.meta.env.VITE_MODE === 'production') {
      if (userStore?.country?.countryCode === 'US') {
        return 32
      } else if (userStore?.country?.countryCode === 'CA') {
        return 33
      }
    }
    return isDesktop ? 27 : 29
  }, [isDesktop, userStore?.country?.countryCode])
  if (pathNaname === '/searchResults' || !isGeneralAdsEnabled()) {
    return null
  }

  return (
    <Wrapper>
      {isDesktop ? (
        <Iframe
          id="a4b874a2"
          name="a4b874a2"
          src={`https://ads.myplace4parts.com/www/delivery/afr.php?refresh=15&zoneid=${getZoneId}&cb=INSERT_RANDOM_NUMBER_HERE`}
          frameBorder="0"
          scrolling="no"
          width="1400"
          height="80"
        ></Iframe>
      ) : (
        <Iframe
          id="a05c7ff8"
          name="a05c7ff8"
          src={`https://ads.myplace4parts.com/www/delivery/afr.php?refresh=15&zoneid=${getZoneId}&cb=INSERT_RANDOM_NUMBER_HERE`}
          frameBorder="0"
          scrolling="no"
          width="358"
          height="50"
        ></Iframe>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-top: 60px;
    display: none;
  }
  @media print {
    display: none;
  }
`
const Iframe = styled.iframe`
  border: 0;
`
