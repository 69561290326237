import { PartAttributes } from 'src/store/models/SearchModels'
import { BaseServiceProvider } from './BaseServiceProvider'
import { Vehicle } from 'src/store/models/Vehicles'
import {
  IByPartPayLoad,
  ImageBypartAPIResponse,
} from 'src/api/searchResults/interfaces'

class PartExtServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'partext', '1.1', '')
  }

  getPartAttributesByPart = async (
    byPartPayLoad: IByPartPayLoad[],
    vehicle?: Vehicle
  ): Promise<Array<ImageBypartAPIResponse>> => {
    try {
      const resp = await this.post<Array<ImageBypartAPIResponse>>(
        `part/attributes/byParts`,
        byPartPayLoad,
        {
          params: {
            catalogId: vehicle?.type ? vehicle.type.id : 111,
          },
        }
      )
      if (resp.status === 200) return resp.data
    } catch (_error) {
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log('error fetching image')
    }

    return null
  }

  getPartAttributesByMultiplesParts = async (
    requestBody: Array<{
      partNumber: string
      lineCode: string
    }>
  ): Promise<Array<PartAttributes>> => {
    try {
      const resp = await this.post<Array<PartAttributes>>(
        `part/attributes/byParts`,
        requestBody
      )
      if (resp.status === 200) {
        const responseData: PartAttributes[] = []
        resp.data.forEach((item, index) => {
          responseData.push({ ...item, lineCode: item.linecode })
          delete responseData[index].linecode
        })
        return responseData
      }
    } catch (_error) {
      // Nothing to do here - just show 'No Image Available'
    }

    return null
  }
}

export default new PartExtServiceProvider()
