import { FormControlLabel } from '@mui/material'
import { ReactElement, useState } from 'react'
import { Translate } from 'src/i18n'
import VehicleServiceProvider from 'src/services/VehicleServiceProvider'
import { useVehicleMakesStore } from '../../store/VehicleMakesStore'
import { observer } from 'mobx-react-lite'
import { IOSStyledSwitch } from 'src/ui-components/util/IOSStyledSwitch'

const CustomMakesSwitch = observer((): ReactElement => {
  const makesStore = useVehicleMakesStore()
  const isMakesFilterEnabled = makesStore.isMakesFilterEnabled()
  const [enabled, setEnabled] = useState(isMakesFilterEnabled) // internal state to show the switch animation immediately rather than wait for the API response

  const handleMakesSwitch = async (e): Promise<void> => {
    e.stopPropagation()
    e.preventDefault()
    const toggle = !enabled
    setEnabled(toggle)
    makesStore.setIsLoadingMakes(true)
    try {
      await VehicleServiceProvider.updateMakesFilterStatus(toggle)
      makesStore.setMakesFilterEnabled(toggle)
    } catch (_e) {
      makesStore.setIsLoadingMakes(false)
      setEnabled(!toggle)
    }
  }

  return (
    <FormControlLabel
      control={
        <IOSStyledSwitch
          sx={{ m: 1 }}
          checked={enabled}
          onClick={handleMakesSwitch}
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
        />
      }
      label={Translate('customMakes')}
    />
  )
})

export default CustomMakesSwitch
