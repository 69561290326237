import { ContactInfo } from 'src/api/cart/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'
import { Address, Country } from 'src/api/user/interfaces'
import { TableColumn } from 'src/features'
import CatalogServiceProvider from './CatalogServiceProvider'
import { IdNamePair } from 'src/store/models/KeyValuePair'
import { State } from 'src/api/vehicle/interfaces'

interface MessagesResponse {
  messages: Array<string>
}

class UserDataServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'userdata', '1.1', '')
  }

  getMessages = async (): Promise<MessagesResponse> => {
    try {
      const resp = await this.get<MessagesResponse>('bannermessages')
      return resp?.data
    } catch (_e) {
      return {
        messages: [],
      }
    }
  }

  contactAdressInfo = async (orgId: string): Promise<ContactInfo> => {
    try {
      const resp = await this.get<ContactInfo>(`storeinfo/${orgId}`)
      return resp.data
    } catch (error) {
      throw new Error(error.toString())
    }
  }

  getCountry = async (): Promise<Country> => {
    const { data } = await this.get<Country>('selectedcatalog')
    return data
  }

  getUserLogo = async (): Promise<string> => {
    const { data } = await this.get<{ logo: string }>('logo')
    return data.logo
  }

  /**
   * Returns a list of catalog type options supported
   */
  getTypeOptions = async (): Promise<Array<TableColumn>> => {
    let typeList = []
    try {
      const resp = await this.get<Array<IdNamePair<number, string>>>('catalogs')
      typeList = resp?.data?.map((item) => {
        const element = {
          id: item?.id,
          value: item?.name,
        }
        return element
      })
    } catch (_e) {
      return []
    }

    const cols: Array<TableColumn> = CatalogServiceProvider.initTableCols(1)
    cols[0].options = typeList
    return cols
  }

  getStatesByCountry = async (countryId: number): Promise<Array<State>> => {
    let statesList
    try {
      const resp = await this.get<Array<Array<State>>>(`region/${countryId}`)
      statesList = resp.data
    } catch (_e) {
      return [] // @TODO: Add better error handling... might want to let the exception propagate
    }
    return statesList
  }

  getShipToAddresses = async (orgId: string): Promise<Address[]> => {
    try {
      const resp = await this.get<Address[]>(`address/${orgId}`)
      return resp.data
    } catch (_e) {
      return []
    }
  }
}

export default new UserDataServiceProvider()
