import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import {
  DiscardAndStartNewOrderActions,
  DuplicateVehicleActions,
  Link,
  ToastNotificationVariant,
} from '../../ui-components/util/Interfaces'
import ShowNotification from 'src/ui-components/modals/ToastNotifcationNotiStack'
import { ModalNotificationData } from '../../ui-components/modals/ModalNotification'
import { ChangeLocationModalData } from '../../features/changeLocation/components/interfaces'
import { StoreInstances } from '../StoreInstancesContainer'
import { Vehicle } from '../models/Vehicles'
import { ProductModel } from '../models/ProductModel'
import { DDSUnavailableInterface } from 'src/features/orderHistory/components/ddsAdvancedTracker/interfaces'
import ListNode from '../lists/ListNode'
import { UserAttributeKey } from '../user/interfaces'
import { UuidType } from 'src/services/attributes/AttributesModel'
import AttributesServiceProvider from 'src/services/AttributesServiceProvider'
import { getCondensedViewState } from 'src/helpers/condensedViewUtil'
import { StorageKeys } from 'src/config/constants'

interface FitmentConditionsDialog {
  proceed: () => void
  cancel: () => void
}

interface PromisedModal<T> {
  resolve: (a: T) => void
  reject: (a: unknown) => void
}

interface DuplicateVehicleModalParams<T> {
  promise: PromisedModal<T>
  vehicle: Vehicle
}

interface DiscardAndStartNewOrderProps {
  promise: PromisedModal<DiscardAndStartNewOrderActions>
}

interface IListStoreProps {
  selectedGroupValue?: number
  selectedList?: ListNode
  selectedGroup?: ListNode
  selectedListGroups?: Array<ListNode>
  selectedCategory?: ListNode
}

export enum CatalogTab {
  PARTS_CATALOG = 'PARTS_CATALOG_TAB',
  AST_CATALOG = 'AST_CATALOG_TAB',
  JOBS_CATALOG = 'JOBS_CATALOG_TAB',
}

export class UiStateStore {
  fitmentConditionsDialogOptions: FitmentConditionsDialog

  showFitmentConditions = false

  showGfxConditions = false

  gfxConditionsActiveStep = 1

  showLocationModal = false

  showLaborLookupModal = false

  showLaborLookupSearchResultsModal = false

  showLaborResultModal = false

  showLaborResultSearchResultsModal = false

  locationModalData: ChangeLocationModalData | undefined

  showPartsCatalogDiagramDrawer = false

  showDetailCatalogDiagram = false

  modalNotificationData: ModalNotificationData | undefined

  showModalNotification = false

  showSwitchLookupModal = false

  showEnginesModal = false
  showMobilePriceInformationDrawer = false
  showCustomizeSearchModal = false

  // Lines Filter drawer open state
  showFilterLinesDrawer = false

  // Report LostSale Drawer open state
  showReportLostSaleDrawer = false

  showContactDrawer = false

  // Brand Links Drawer open state
  showBrandLinksDrawer = false

  // Brand Links Drawer data state
  brandLinksDrawerData: ProductModel | null = null

  // move to another list drawer state
  showMoveToAnotherListDrawer = false

  showMoveToNextListDrawer = false

  showCartPartDetailsDrawer = false

  showBannerPromotion = false

  //  Show Service Intervals Drawer
  showServiceIntervalsDrawer = false

  // move to edit item drawer State
  showEditItemsDrawer = false

  // move to chnage loaction drawer state
  showChangeLoactionDrawer = false

  private _selectedCatalogTab: CatalogTab

  private _showDuplicateVehicleModal = false

  private _showDiscardAndStartNewOrderModal = false

  discardAndStartNewOrderModalParams: DiscardAndStartNewOrderProps

  duplicateVehicleModalParams: DuplicateVehicleModalParams<DuplicateVehicleActions>

  showMultiPartInquiryDrawer = false

  showMultiPartInquiryImportDrawer = false

  ddsUnavailableDrawerData: DDSUnavailableInterface = {
    orderNumber: '',
    orderDate: '',
    message: '',
  }

  editPartsSelection = false

  public bannerMessage = ''

  // Report LostSale Drawer open state
  isCondensedView = getCondensedViewState()

  public listStoreProps: IListStoreProps = {
    selectedGroupValue: 0,
    selectedList: undefined,
    selectedGroup: undefined,
    selectedListGroups: [],
  }

  private _skipAddVehicleCallback: () => void

  constructor() {
    makeAutoObservable(this)
    this._selectedCatalogTab = CatalogTab.PARTS_CATALOG
  }

  showFitmentConditionsDialog = (p: FitmentConditionsDialog): void => {
    this.showFitmentConditions = true
    this.fitmentConditionsDialogOptions = p
  }

  hideFitmentConditionsDialog = (): void => {
    this.showFitmentConditions = false
  }

  public openGfxConditions = (): void => {
    this.showGfxConditions = true
    this.gfxConditionsActiveStep = 1
  }

  public setShowEnginesModal = (show: boolean): void => {
    this.showEnginesModal = show
  }

  public closeGfxConditions = (): void => {
    this.showGfxConditions = false
  }

  public setGfxConditionsActiveStep = (step: number): void => {
    this.gfxConditionsActiveStep = step
  }

  public openLocationSelector = (data: ChangeLocationModalData): void => {
    this.locationModalData = data
    this.showLocationModal = true
  }

  public setShowMobilePriceInformationDrawer = (show: boolean): void => {
    this.showMobilePriceInformationDrawer = show
  }

  public closeLocationSelector = (): void => {
    this.locationModalData = undefined
    this.showLocationModal = false
  }

  setShowPartsCatalogDiagramDrawer = (
    showPartsCatalogDiagramDrawer: boolean
  ): void => {
    this.showPartsCatalogDiagramDrawer = showPartsCatalogDiagramDrawer
  }

  setShowDetailCatalogDiagram = (showDetailCatalogDiagram: boolean): void => {
    this.showDetailCatalogDiagram = showDetailCatalogDiagram
  }

  openModalNotification(props: ModalNotificationData): void {
    this.modalNotificationData = props
    this.showModalNotification = true
  }

  closeModalNotification(): void {
    this.showModalNotification = false
    this.modalNotificationData = undefined
  }

  displaySuccessNotification = (
    message: string,
    link?: Link,
    messageParams?: Array<string | number>
  ): void => {
    ShowNotification({
      ...{
        open: true,
        message,
        messageParams,
        variant: ToastNotificationVariant.SUCCESS,
        link,
      },
      message,
    })
  }

  displayWarningNotification = (
    message: string,
    link?: Link,
    time?: number,
    messageParams?: Array<string | number>
  ): void => {
    ShowNotification({
      ...{
        open: true,
        message,
        messageParams,
        variant: ToastNotificationVariant.WARNING,
        link,
        time,
      },
    })
  }

  displayErrorNotification = (
    message: string,
    link?: Link,
    time?: number,
    messageParams?: Array<string | number>
  ): void => {
    ShowNotification({
      ...{
        open: true,
        message,
        messageParams,
        variant: ToastNotificationVariant.ERROR,
        link,
        time,
      },
    })
  }

  setShowSwitchLookupModal = (show: boolean): void => {
    this.showSwitchLookupModal = show
  }

  setShowCustomizeSearchModal = (show: boolean): void => {
    this.showCustomizeSearchModal = show
  }

  public setShowLaborLookupModal = (show: boolean): void => {
    this.showLaborLookupModal = show
  }

  public setShowLaborLookupSearchResultsModal = (show: boolean): void => {
    this.showLaborLookupSearchResultsModal = show
  }

  public setShowLaborResultModal = (show: boolean): void => {
    this.showLaborResultModal = show
  }

  public setShowLaborResultSearchResultsModal = (show: boolean): void => {
    this.showLaborResultSearchResultsModal = show
  }

  // FilterLinesDrawer
  public setShowFilterLinesDrawer = (showFilterLinesDrawer: boolean): void => {
    this.showFilterLinesDrawer = showFilterLinesDrawer
  }

  // ReportLostSaleDrawer
  public setShowReportLostSaleDrawer = (
    showReportLostSaleDrawer: boolean
  ): void => {
    this.showReportLostSaleDrawer = showReportLostSaleDrawer
  }

  // BrandLinksDrawer
  public setShowBrandLinksDrawer = (
    showBrandLinksDrawer: boolean,
    product: ProductModel
  ): void => {
    this.showBrandLinksDrawer = showBrandLinksDrawer
    this.brandLinksDrawerData = product
  }

  // MoveToAnotherListDrawer
  public setMoveToAnotherListDrawer = (
    showMoveToAnotherListDrawer: boolean
  ): void => {
    this.showMoveToAnotherListDrawer = showMoveToAnotherListDrawer
  }

  // MoveToAnotherListDrawer
  public setMoveToNextListDrawer = (
    showMoveToNextListDrawer: boolean
  ): void => {
    this.showMoveToNextListDrawer = showMoveToNextListDrawer
  }

  // ShowCartPartDetailsDrawer
  public setCartPartDetailsDrawer = (
    showCartPartDetailsDrawer: boolean
  ): void => {
    this.showCartPartDetailsDrawer = showCartPartDetailsDrawer
  }

  // ShowBannerPromotion
  public setShowBannerPromotion = (showBannerPromotion: boolean): void => {
    this.showBannerPromotion = showBannerPromotion
  }

  public setShowContactDrawer = (showContactDrawer: boolean): void => {
    this.showContactDrawer = showContactDrawer
  }

  // ShowServiceIntervalsDrawer
  public setShowServiceIntervalsDrawer = (
    showServiceIntervalsDrawer: boolean
  ): void => {
    this.showServiceIntervalsDrawer = showServiceIntervalsDrawer
  }

  // MoveedititemsDrawer
  public setShowEditItemsDrawer = (showEditItemsDrawer: boolean): void => {
    this.showEditItemsDrawer = showEditItemsDrawer
  }

  // MoveedchangeloactionDrawer
  public setShowChangeLoactionDrawer = (
    showChangeLoactionDrawer: boolean
  ): void => {
    this.showChangeLoactionDrawer = showChangeLoactionDrawer
  }

  get showDuplicateVehicleModal(): boolean {
    return this._showDuplicateVehicleModal
  }

  get showDiscardAndStartNewOrderModal(): boolean {
    return this._showDiscardAndStartNewOrderModal
  }

  public shouldDiscardAndStartNewCart =
    async (): Promise<DiscardAndStartNewOrderActions> => {
      this._showDiscardAndStartNewOrderModal = true
      return new Promise<DiscardAndStartNewOrderActions>((resolve, reject) => {
        this.discardAndStartNewOrderModalParams = {
          promise: { resolve, reject },
        }
      }).finally(() => (this._showDiscardAndStartNewOrderModal = false))
    }

  public shouldMergeCarts = async (
    vehicle: Vehicle
  ): Promise<DuplicateVehicleActions> => {
    if (StoreInstances.cart.vehicleProducts(vehicle).length > 0) {
      this._showDuplicateVehicleModal = true
    } else {
      return Promise.resolve(DuplicateVehicleActions.MERGE)
    }
    const promise = await new Promise<DuplicateVehicleActions>(
      (resolve, reject) => {
        const modalParams = {
          vehicle,
          promise: { resolve, reject },
        }
        this.duplicateVehicleModalParams = modalParams
      }
    ).finally(() => {
      this._showDuplicateVehicleModal = false
    })
    return promise
  }

  get selectedCatalogTab(): CatalogTab {
    return this._selectedCatalogTab
  }

  set selectedCatalogTab(tab: CatalogTab) {
    this._selectedCatalogTab = tab
  }

  public setShowMultiPartInquiryDrawer = (value: boolean): void => {
    this.showMultiPartInquiryDrawer = value
  }

  public setShowMultiPartInquiryImportDrawer = (value: boolean): void => {
    this.showMultiPartInquiryImportDrawer = value
  }

  public openDDSUnavailableDrawer = (data: DDSUnavailableInterface): void => {
    this.ddsUnavailableDrawerData = data
  }

  public closeDDSUnavailableDrawer = (): void => {
    this.ddsUnavailableDrawerData = {
      orderNumber: '',
      orderDate: '',
      message: '',
    }
  }

  public setEditPartsSelection = (value: boolean): void => {
    this.editPartsSelection = value
  }

  public skipAddVehicleCallback = (): (() => void) => {
    return this._skipAddVehicleCallback
  }

  public setSkipAddVehicleCallback = (callback: () => void): void => {
    this._skipAddVehicleCallback = callback
  }

  public setBannerMessage = (message: string): void => {
    this.bannerMessage = message
  }

  public showYourCost = (): boolean => {
    const showCostValue = StoreInstances?.userStore?.getUserAttribute(
      UserAttributeKey.display_priceFld
    )
    return showCostValue === 'Blank' ? false : true
  }

  public updateYourCost = (): void => {
    const payloadAttributeValue = this.showYourCost() ? 'Blank' : '$'
    const { userId } = StoreInstances.userStore.preferences
    const payload = {
      uuid: UuidType.SHOW_YOUR_COST,
      domId: Number(userId),
      domType: 5,
      attrValue: payloadAttributeValue,
    }
    AttributesServiceProvider.saveOrUpdateAttribute([payload]).then(
      (updatedStatus) => {
        if (updatedStatus === 200) {
          this.showYourCost()
        }
      }
    )
  }

  public isPromoEnabled = (): boolean => {
    const promoValue = StoreInstances?.userStore?.getUserAttribute(
      UserAttributeKey.promo_enabled
    )

    return promoValue === 'true'
  }

  setIsCondensedView = (value: boolean): void => {
    this.isCondensedView = value
    localStorage.setItem(StorageKeys.CONDENSEDVIEW_KEY, JSON.stringify(value))
  }
}

export const UiStateStoreContext = createContext<UiStateStore | undefined>(
  undefined
)
// https://stackoverflow.com/a/69735347
export const useUiStateStore = (): UiStateStore => {
  const uiStateStore = useContext(UiStateStoreContext)
  if (!uiStateStore) {
    throw new Error(
      'No UiStateStoreContext.Provider found when calling useUiStateStore.'
    )
  }
  return uiStateStore
}
