import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios'

import history from 'src/helpers/history'
import urlJoin from 'url-join'
import authManager from '../api/security/Auth'

const instance = axios.create({
  timeout: 60000,
})

instance.interceptors.request.use(async (config) => {
  let currentToken
  try {
    currentToken = await authManager.getCurrentToken()
  } catch (_e) {
    history.push('/login')
    return config
  }

  const headersToSend: { [key: string]: unknown } = {
    Authorization: currentToken,
  }

  // https://github.com/axios/axios/issues/5034#issuecomment-1408548737
  return {
    ...config,
    headers: { ...headersToSend, ...config.headers } as AxiosRequestHeaders,
  }
})

export class BaseServiceProvider {
  private constructedUrl: string | undefined
  constructor(
    private baseUrl: string,
    private service: string,
    private version: string,
    private servicePrefix: string = ''
  ) {
    this.constructedUrl = urlJoin(
      this.baseUrl,
      this.service,
      this.version,
      this.servicePrefix
    )
  }

  public getConstructedUrl(): string {
    return this.constructedUrl
  }

  public get<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request({
      ...config,
      url,
      method: 'GET',
    })
  }

  public delete<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request({
      ...config,
      url,
      method: 'DELETE',
    })
  }

  public post<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request({
      ...config,
      data,
      url,
      method: 'POST',
    })
  }

  public put<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request({
      ...config,
      data,
      url,
      method: 'PUT',
    })
  }

  public patch<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request({
      ...config,
      data,
      url,
      method: 'PATCH',
    })
  }

  public request<T = unknown, R = AxiosResponse<T>, D = unknown>(
    config: AxiosRequestConfig<D>
  ): Promise<R> {
    return instance.request({
      baseURL: this.constructedUrl,
      ...this.beforeEachRequest(config),
    })
  }

  public beforeEachRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    return config
  }
}

export default BaseServiceProvider
