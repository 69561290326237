import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import { I18n, Translate } from 'src/i18n'
import { navTextStyle } from '../CommonStyles'
import { activeStyle } from '../CommonStyles'
import { navLinkStyle } from '../CommonStyles'

export interface IMenuItem {
  path: string
  text: string
  onClick?: () => void
}

export const MenuItem = (props: IMenuItem): ReactElement => {
  const { path, text, onClick } = props
  return (
    <NavLink
      exact
      to={path}
      activeStyle={activeStyle}
      style={navLinkStyle}
      onClick={onClick}
    >
      <I18n name={Translate(text)} style={navTextStyle} />
    </NavLink>
  )
}

MenuItem.defaultProps = {
  onClick: () => undefined,
}

export default MenuItem
