import { featureFlagFilter } from 'src/features/navigation/TopNavigation/components/MenuItemLinks/Filter'
import { IMenuItem } from 'src/features/navigation/TopNavigation/components/MenuItemLinks/MenuItem'
import { useSearchStore } from 'src/store/search/SearchStore'

export const useMenuItems = (): IMenuItem[] => {
  const searchStore = useSearchStore()

  const handleClickPartsCatalog = () => {
    searchStore.setShowVehicleTransition(false)
  }

  const menuItems: IMenuItem[] = [
    { text: 'home', path: '/home' },
    {
      text: 'partsCatalog',
      path: '/partsCatalog',
      onClick: handleClickPartsCatalog,
    },
    { text: 'orders', path: '/orderHistory' },
    { text: 'quotes', path: '/quotes' },
    { text: 'lists', path: '/lists' },
    { text: 'vehicles', path: '/vehicleHistory' },
  ]

  const filteredItems = featureFlagFilter(menuItems)
  return filteredItems
}
