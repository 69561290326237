import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from '@mui/material'
import styled from 'styled-components'
import { Close, ExpandMore, FilterList, Search } from '@mui/icons-material'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useVehicleSpecification } from 'src/store/vehicleSpecification/VehicleSpecificationStore'
import { observer } from 'mobx-react-lite'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import { I18n, Translate } from 'src/i18n'
import { Button, Checkbox } from 'src/ui-components'
import { Spinner } from 'react-bootstrap'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'
import {
  VehicleSpecification,
  VehicleSpecificationCartItem,
} from 'src/store/models/VehicleSpecification'
import { Hyperlink } from 'src/ui-components/util/Hyperlink'
import { CatalogNodeType } from 'src/store/partsCatalog'
import { CatalogNode } from 'src/store/partsCatalog/CatalogNode'
import { useHistory } from 'react-router-dom'
import { usePartsCatalog } from 'src/store/partsCatalog/PartsCatalog'
import { useSearchStore } from 'src/store/search/SearchStore'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'

interface VehicleSpecificationProps {
  onClose: () => void
  openWithFilters: boolean
  open: boolean
}

export const VehicleSpecificationDrawer = observer(
  (props: VehicleSpecificationProps): ReactElement => {
    const history = useHistory()
    const partsCatalog = usePartsCatalog()
    const { displaySuccessNotification } = useUiStateStore()
    const { onClose, openWithFilters, open } = props
    const { currentVehicle } = useSearchStore()
    const { loadingVehicleSpecification, vehicleSpecifications } =
      useVehicleSpecification()
    const { getVehicleSpecification, setVehicleSpecification } =
      useShoppingCart()
    const [isExpandAllChecked, setIsExpandAllChecked] = useState(false)
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)
    const [expandArray, setExpandArray] = useState<boolean[]>([])
    const [selectedParts, setSelectedParts] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState<string>('')
    const [localVehicleSpecs, setLocalVehicleSpecs] = useState<
      VehicleSpecification[]
    >([])
    const [showFilteredResults, setShowFilteredResults] = useState(false)
    const [groupsSelected, setGroupsSelected] = useState<CatalogNode[]>([])

    const currentVehicleSpecification = getVehicleSpecification(currentVehicle)

    const storeGroupsSelected = openWithFilters
      ? partsCatalog.root.getAllDescendants(
          (node) => node.type === CatalogNodeType.GROUP && node.selected
        )
      : []

    const specificationUpdateText = Translate('specificationUpdate')
    const viewCartLink = {
      text: Translate('viewCart'),
      action: () => {
        history.push('/cart')
      },
    }

    useEffect(() => {
      selectSavedParts()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVehicleSpecification])

    useEffect(() => {
      resetFilters()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openWithFilters, open])

    useEffect(() => {
      if (groupsSelected.length > 0) {
        const selectedGroupsId = groupsSelected.map((group) => group.id)
        setLocalVehicleSpecs(
          vehicleSpecifications.filter((specification) =>
            selectedGroupsId.includes(specification.groupId)
          )
        )
      } else {
        setLocalVehicleSpecs(vehicleSpecifications)
      }
    }, [vehicleSpecifications, groupsSelected])

    useEffect(() => {
      const isUserSearching = searchValue && searchValue.length > 0
      setExpandArray(localVehicleSpecs.map(() => isUserSearching || false))

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localVehicleSpecs])

    useEffect(() => {
      if (expandArray.length <= 0) return

      setExpandArray(expandArray.map((_) => isExpandAllChecked))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandAllChecked])

    const getFilteredVehicleSpecs = () => {
      if (groupsSelected.length > 0) {
        const selectedGroupsId = groupsSelected.map((group) => group.id)
        return vehicleSpecifications.filter((specification) =>
          selectedGroupsId.includes(specification.groupId)
        )
      } else {
        return vehicleSpecifications
      }
    }

    const filterVehicleSpecsBySearchValue = (
      vehicleSpecsList: VehicleSpecification[]
    ) => {
      const searchValueLowerCase = searchValue.toLowerCase()

      return vehicleSpecsList
        .map((vehicleSpecs) => {
          const specificationItems = vehicleSpecs.specificationItems.filter(
            (item) =>
              item.description.toLowerCase().includes(searchValueLowerCase)
          )
          const groupFilter = vehicleSpecs.groupText
            .toLowerCase()
            .includes(searchValueLowerCase)

          if (specificationItems.length > 0 || groupFilter) {
            return {
              ...vehicleSpecs,
              specificationItems:
                specificationItems.length > 0
                  ? specificationItems
                  : vehicleSpecs.specificationItems,
            }
          }
          return null
        })
        .filter((filterItem) => filterItem !== null)
    }

    useEffect(() => {
      const filteredVehicleSpecs = getFilteredVehicleSpecs()
      const filteredList = filterVehicleSpecsBySearchValue(filteredVehicleSpecs)

      setLocalVehicleSpecs(filteredList)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, vehicleSpecifications])

    const resetFilters = (): void => {
      setGroupsSelected(storeGroupsSelected)
      setShowFilteredResults(storeGroupsSelected?.length > 0)
      setSearchValue('')
    }

    const handleSelectAll = (checkValue: boolean): void => {
      setIsSelectAllChecked(checkValue)
      if (checkValue) {
        const allItems = localVehicleSpecs
          .map((vehicleSpecification) =>
            vehicleSpecification.specificationItems.map((item) => item.hash)
          )
          .reduce((idListX, idListY) => idListX.concat(idListY))

        setSelectedParts(allItems)

        setExpandArray(expandArray.map((_) => true))
      } else {
        setSelectedParts([])
      }
    }

    const selectSavedParts = () => {
      if (currentVehicle == null || currentVehicleSpecification == null) return
      setSelectedParts(currentVehicleSpecification.map((item) => item.hash))
    }

    const handleExpand = (index: number): void => {
      setExpandArray(
        expandArray.map((expand, indexArray) => {
          if (indexArray === index) return !expand
          return expand
        })
      )
    }

    const handleSelectPart = (addOrDeletePart: string): void => {
      setSelectedParts(
        selectedParts.includes(addOrDeletePart)
          ? selectedParts.filter((partNumber) => addOrDeletePart !== partNumber)
          : selectedParts.concat([addOrDeletePart])
      )
    }

    const handleAddToCart = (): void => {
      const selectedSpecificationItems: Array<VehicleSpecificationCartItem> = []
      vehicleSpecifications.forEach((specification) => {
        specification.specificationItems
          .filter((item) => selectedParts.includes(item.hash))
          .forEach((filtered) => {
            selectedSpecificationItems.push({
              ...filtered,
              groupId: specification.groupId,
            })
          })
      })
      setVehicleSpecification(currentVehicle, selectedSpecificationItems)
      displaySuccessNotification(specificationUpdateText, viewCartLink)
      handleOnclose()
    }

    const handleOnclose = (): void => {
      selectSavedParts()
      onClose()
    }

    const handleApplyResultFilter = (): void => {
      setShowFilteredResults(true)
      setIsSelectAllChecked(false)
      setIsExpandAllChecked(false)
      setSelectedParts([])
      resetFilters()
    }

    const handleRemoveGroup = (groupId: string): void => {
      setGroupsSelected(groupsSelected.filter((group) => group.id !== groupId))
    }

    const selectedPartsCounts = (groupId: string): number => {
      return localVehicleSpecs
        .find((group) => group.groupId === groupId)
        .specificationItems.filter((item) => selectedParts.includes(item.hash))
        .length
    }

    const selectAllExpandAllCheckboxes = (
      <FilteredGridResult
        container
        rowSpacing={1}
        columnSpacing={3}
        marginY={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <FilteredGridResult item xs={7} paddingLeft={0}>
          {groupsSelected.length === 0 && storeGroupsSelected.length > 0 && (
            <Hyperlink action={handleApplyResultFilter} underlined={false}>
              <FilterList className="mr-3" fontSize="small" />
              Apply Result Filter
            </Hyperlink>
          )}
        </FilteredGridResult>
        {localVehicleSpecs.length > 0 && (
          <SelectExpandCheckbox>
            <Grid item>
              <Checkbox
                value=""
                onClick={() => {
                  setIsExpandAllChecked(!isExpandAllChecked)
                }}
                active={isExpandAllChecked}
                label={Translate('expandAll')}
              />
            </Grid>
            <Grid item>
              <Checkbox
                value=""
                onClick={() => handleSelectAll(!isSelectAllChecked)}
                active={isSelectAllChecked}
                label={Translate('selectAll')}
              />
            </Grid>
          </SelectExpandCheckbox>
        )}
      </FilteredGridResult>
    )

    const resultFilters = (
      <FilteredGridResult container columnSpacing={2} marginY={2}>
        {showFilteredResults && (
          <>
            <Grid item paddingY={1}>
              <I18n name="resultsFilter" />:
            </Grid>

            {groupsSelected.map((group) => (
              <Grid item paddingLeft={0} key={`groupId-${group.id}`}>
                <FilterButton onClick={() => handleRemoveGroup(group.id)}>
                  <Close fontSize="small" /> {group.value}
                </FilterButton>
              </Grid>
            ))}
          </>
        )}
      </FilteredGridResult>
    )

    const modalHeader = (
      <>
        <Grid container columnSpacing={1}>
          <SpecificationVehicleInfo>
            <GridItemCarModel fontWeight={700} item>
              {`${currentVehicle.year?.value} ${currentVehicle.make?.value} ${currentVehicle.model?.value}`}
            </GridItemCarModel>
            <GridItemCarModel item>
              {currentVehicle.engine?.value}
            </GridItemCarModel>
          </SpecificationVehicleInfo>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={1} marginTop={2}>
          <Grid item xs={12}>
            <FieldWrapper>
              <StyledSearch />
              <Field
                value={searchValue}
                type="text"
                placeholder={Translate('specificationSearch')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearchValue(e.target.value)
                }
              />
            </FieldWrapper>
          </Grid>
        </Grid>
        {showFilteredResults && groupsSelected.length > 0
          ? resultFilters
          : selectAllExpandAllCheckboxes}
      </>
    )

    const allResults = (
      <Box
        paddingTop={2}
        overflow="auto"
        height="auto"
        flexDirection="column"
        display="flex"
      >
        <Grid
          container
          rowSpacing={5}
          columnSpacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} paddingY={10}>
            {localVehicleSpecs.length > 0 ? (
              localVehicleSpecs.map((group, index) => (
                <StyledAccordion
                  elevation={0}
                  expanded={expandArray[index] || false}
                  onChange={() => handleExpand(index)}
                  key={group.groupId}
                >
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <StyledAccordionSummaryContainer>
                      <StyledAccordionTitle>
                        {group.groupText}
                      </StyledAccordionTitle>
                      {selectedPartsCounts(group.groupId) > 0 && (
                        <StyledAccordionSubTitle>
                          {selectedPartsCounts(group.groupId)} Selected
                        </StyledAccordionSubTitle>
                      )}
                    </StyledAccordionSummaryContainer>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    {group.specificationItems.map((specification) => (
                      <Grid container key={specification.hash}>
                        <Grid item xs={1} className="mt-1">
                          <Checkbox
                            value=""
                            active={selectedParts.includes(specification.hash)}
                            label=""
                            onClick={() => {
                              handleSelectPart(specification.hash)
                            }}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <StyledText>{specification.description}</StyledText>
                          <br />
                          {specification.extendedDescription}
                          {` ${specification.partNumber}`}
                          {`, Years: ${specification.years}`}
                          <br />
                          {specification.miscellaneousTexts?.map(
                            (text, indexSpec) =>
                              `${text} ${
                                indexSpec <=
                                specification.miscellaneousTexts.length
                                  ? ''
                                  : ', '
                              }`
                          )}
                          <Hr />
                        </Grid>
                      </Grid>
                    ))}
                  </StyledAccordionDetails>
                </StyledAccordion>
              ))
            ) : (
              <>No Results</>
            )}
          </Grid>
        </Grid>
      </Box>
    )

    const modalBody = loadingVehicleSpecification ? (
      <Grid
        container
        rowSpacing={5}
        columnSpacing={1}
        marginTop={1}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={1} paddingTop={10}>
          <Spinner animation="border" />
        </Grid>
      </Grid>
    ) : (
      allResults
    )

    const updateCartLabel = currentVehicleSpecification
      ? currentVehicleSpecification.length > 0
      : false

    const modalFooter = (
      <FooterGrid container rowSpacing={5} columnSpacing={1}>
        <Grid item xs={12} paddingTop={1}>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="secondary" text="cancel" onClick={handleOnclose} />
            <Button
              icon={
                selectedParts.length > 0 ? (
                  <SelectedSpecification>
                    {selectedParts.length}
                  </SelectedSpecification>
                ) : null
              }
              text={
                updateCartLabel
                  ? Translate('updateCart')
                  : Translate('addToCart')
              }
              disabled={updateCartLabel ? false : selectedParts.length === 0}
              onClick={handleAddToCart}
            />
          </Box>
        </Grid>
      </FooterGrid>
    )

    return (
      <BaseDrawer
        open={openWithFilters || open}
        width={window.innerWidth < breakpoints.tablet ? '100%' : '700px'}
        title={`${Translate('vehicleSpecification')}`}
        onClose={handleOnclose}
        header={modalHeader}
        showHeaderDivider
        body={modalBody}
        footer={modalFooter}
      />
    )
  }
)

const SpecificationVehicleInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin-top: 20px;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
  }
`
const SelectExpandCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
  }
`

const SelectedSpecification = styled.span`
  background-color: ${defaultTheme.colors.black};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 24px;
  font-family: 'Roboto', serif;
  margin-right: 6px;
  color: ${defaultTheme.colors.white};
  display: inline-block;
`

const FilteredGridResult = styled(Grid)`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 26px;
}
  }
`

const FooterGrid = styled(Grid)`
  position: sticky;
  background-color: ${defaultTheme.colors.white};
`

const ExpandMoreIcon = styled(ExpandMore)`
  pointer-events: initial;
  cursor: pointer;
  width: 100%;
  color: ${defaultTheme.colors.black};
`

const StyledSearch = styled(Search)`
  margin: 4px 4px 4px 8px;
  font-size: 20px;
  cursor: pointer;
  color: ${defaultTheme.colors.disabledButtonText};
  align-self: center;
`
const GridItemCarModel = styled(Grid)`
  font-size: 18px;
  line-height: 24px;
  font-family: 'Roboto', serif;
`

const StyledAccordion = styled(Accordion)`
  background-color: ${defaultTheme.colors.rowFeaturedBackground};
  :before {
    height: 0;
  }
`
const StyledAccordionTitle = styled.span`
  margin-left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`

const StyledAccordionSubTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`

const StyledAccordionSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 60px;
  flex-direction: row-reverse;
  border-bottom: 1px solid #adadaa;
`

const StyledAccordionDetails = styled(AccordionDetails)`
  background: #ededed;
`

const FilterButton = styled.div`
  height: 36px;
  background: ${defaultTheme.colors.disabledBackground};
  border-radius: 6px;
  padding: 8px 10px 5px;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

const FieldWrapper = styled.div`
  background: ${defaultTheme.colors.componentsBackground};
  border: ${defaultTheme.fieldBorder};
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  height: 48px;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const Field = styled.input`
  border: none;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
  &:focus {
    outline-width: 0;
    outline: none;
  }
  &::placeholder {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${defaultTheme.colors.greyDark};
    text-overflow: ellipsis;
    content: 'Line 1\A Line 2\A Line 3\A';
  }
`

const Hr = styled.hr`
  border-top: 1px solid #dad9d7;
  opacity: 1;
  margin-bottom: 4px;
`

const StyledText = styled.span`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`
