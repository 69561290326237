import { ShoppingCart } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'

export const ShoppingCartMenuIcon = observer(
  (props: { qty: number }): ReactElement => {
    const { qty } = props
    return (
      <CartIconWrapper>
        <ShopingIconButton aria-label="ShoppingCart">
          <ShoppingCartIcon style={{ fontSize: 33 }} />
          <ShoppingCartIconMobile style={{ fontSize: 25 }} />
        </ShopingIconButton>
        {qty > 0 && <CartQuantity>{qty}</CartQuantity>}
      </CartIconWrapper>
    )
  }
)

export const ShoppingCartMenu = observer(
  (props: { qty: number }): ReactElement => {
    const { qty } = props
    return (
      <NavLink to="/cart">
        <ShoppingCartMenuIcon qty={qty} />
      </NavLink>
    )
  }
)

export const NavigationItemsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  gap: 32px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

export const LogoImage = styled.img`
  width: 167px;
  height: 65px;
  margin-left: 15px;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    width: 101.17px;
    height: 37.42px;
    margin-left: 0px;
  }
`

export const BlackBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);

  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 1461px;
  }

  @media screen and (${breakpoints.laptopLarge}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 978px;
    max-width: 1461px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopLarge}px) {
    min-width: 871px;
    max-width: 978px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 799px;
    max-width: 871px;
  }

  @media screen and (${breakpoints.tabletXLarge}px < width <= ${breakpoints.laptop}px) {
    min-width: 791px;
    max-width: 799px;
  }
`

export const WhiteBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  padding: 0px 21px 0px 40px;

  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 459px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 409px;
    max-width: 459px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    padding: 0px 24px 0px 16px;
    min-width: 225px;
    max-width: 409px;
  }

  @media screen and (${breakpoints.tabletXLarge}px < width <= ${breakpoints.laptop}px) {
    padding: 0px 24px 0px 8px;
    width: 225px;
  }

  @media screen and (width <= ${breakpoints.tabletXLarge}px) {
    padding: 0px 24px 0px 8px;
    width: 225px;
  }

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    gap: 0;
    background-color: #000;
    width: fit-content;
  }

  /*@media (width < ${breakpoints.laptopMedium}px) {
    width: 33%;
    gap: 0px;
    margin-right: 10px;
  } */
`

export const WhiteBackgroundEnd = styled(WhiteBackground)`
  justify-content: flex-end;
  cursor: auto;
`

export const CartIconWrapper = styled.div`
  display: inline-block;
  position: relative;
`

export const CartQuantity = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  font-size: 14px;
  font-family: Oswald, serif;
  height: 24px;
  width: 24px;
  background-color: ${(p) => p.theme.colors.primary};
  color: #000;
  text-decoration: none;
  text-align: center;
`

const ShopingIconButton = styled(IconButton)`
  padding: 0px;
`

export const ShoppingCartIcon = styled(ShoppingCart)`
  color: ${defaultTheme.colors.black};
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

export const ShoppingCartIconMobile = styled(ShoppingCart)`
  display: none;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    color: ${defaultTheme.colors.white};
    display: block;
  }
`
