/**
 * JavaScript copied from the `src/main/webapp/test/NavigationPanel.jsp` in aesServlet and made typescript compliant.
 * Helps with the compatibility of the initialization of the integrations.
 */

import {
  CartButtonValues,
  IParsedGoShoppingParams,
} from './api/embedded/types/partsBasketSpec'
import queryParser from './helpers/QueryParser'

/**
 * Properties used by aesServlet. not used here.
 */
interface ILocalGoShoppingParams extends IParsedGoShoppingParams {
  cancel?: boolean
  cartData?: boolean
  fromLocalInventory?: boolean
  Status?: string
  token?: string
}

export const defaultCredentials: IParsedGoShoppingParams = {
  partnerId: 'Alliance Development 1',
  buyerId: '31313',
  sellerId: '111986',
  useDataIsland: false,
  localInventory: false,
  aesServer: false,
  partsBasket: '{}',
}

// Overrides for testing.
// defaultCredentials.partnerId = ''
// defaultCredentials.buyerId = ''
// defaultCredentials.sellerId = ''

// defaultCredentials.partsBasket = JSON.stringify({
//   searchQualifier: {
//     catalogId: 'EPE',
//     indexId: 'PTS',
//     searchTxt: '',
//     vehicleQualifier: {
//       ymmeNo: '',
//       ymme: '',
//       vin: '',
//       aces: '',
//       shortHand: '',
//     },
//   },
// })
// Partial ymme.
// defaultCredentials.partsBasket = JSON.stringify({
//   searchQualifier: {
//     catalogId: 'EPE',
//     indexId: 'PTS',
//     searchTxt: '',
//     vehicleQualifier: {
//       ymmeNo: '',
//       ymme: '2012,honda,accord,2.0+l',
//       vin: '',
//       aces: '',
//       shortHand: '',
//     },
//   },
// })
// Ford truck ymmeNo.
// defaultCredentials.partsBasket = JSON.stringify({
//   searchQualifier: {
//     catalogId: 'EPE',
//     indexId: 'PTS',
//     searchTxt: '',
//     vehicleQualifier: {
//       ymmeNo: '2017,56,31,52',
//       ymme: '',
//       vin: '',
//       aces: '',
//       shortHand: '',
//     },
//   },
// })

type ServletInitParams = { data: IParsedGoShoppingParams }

function servletInit(
  e: ServletInitParams,
  resolve: (
    value: IParsedGoShoppingParams | PromiseLike<IParsedGoShoppingParams>
  ) => void
) {
  let cred: IParsedGoShoppingParams | undefined
  if (typeof e.data == 'object') {
    cred = e.data
  } else if (typeof e.data == 'string') {
    cred = JSON.parse(e.data).params
  }

  if (cred && cred.partnerId) {
    resolve(cred)
  } else {
    resolve(defaultCredentials)
  }
}

function getCredentialsFromWebServer(
  resolve: (
    value: IParsedGoShoppingParams | PromiseLike<IParsedGoShoppingParams>
  ) => void
) {
  if (!window.addEventListener) {
    return
  }

  window.addEventListener(
    'message',
    function (e) {
      let parsedData: ILocalGoShoppingParams | undefined = undefined

      try {
        if (typeof e.data == 'object') {
          parsedData = e.data
        } else if (typeof e.data == 'string') {
          parsedData = JSON.parse(e.data)
        }
      } catch (_e) {
        //
      }

      if (!parsedData || parsedData.cancel || parsedData.cartData) {
        return
      }

      if (
        (parsedData && parsedData.fromLocalInventory) ||
        parsedData.Status ||
        (parsedData != undefined && parsedData.token == 'getServletToken')
      ) {
        //
      } else {
        //Moved all code to servletInit method for re-use.
        servletInit(e, resolve)
      }
    },
    false
  )

  parent.postMessage(
    JSON.stringify({
      sendCredentials: true,
    }),
    '*'
  )
}

function parseBoolean(value: unknown): boolean {
  return typeof value === 'string' && value.toLocaleLowerCase() === 'true'
}

function init(): Promise<IParsedGoShoppingParams> {
  return new Promise((resolve) => {
    const sellerId = queryParser.getParam('sellerId')
    const hookUrl = queryParser.getParam('HOOK_URL')
    const buyerId = queryParser.getParam('buyerId')
    const partnerId = queryParser.getParam('partnerId')
    const goShoppingUrl = queryParser.getParam('goShoppingUrl')
    const useDataIsland = parseBoolean(queryParser.getParam('useDataIsland'))
    const localInventory = parseBoolean(queryParser.getParam('localInventory'))
    let width = queryParser.getParam('width')
    let height = queryParser.getParam('height')
    const partsBasket = queryParser.getParam('partsBasket')
    const cartButton = queryParser.getParam('cartButton')
    const showHeader = queryParser.getParam('showHeader')
    const service = queryParser.getParam('service')
    const checkAvailabilityRedirect = parseBoolean(
      queryParser.getParam('checkAvailabilityRedirect')
    )
    const aesServer = parseBoolean(queryParser.getParam('aesServer'))

    if (height == null || width == null) {
      width = '1016'
      height = '560'
    }
    if (parseInt(width) < 1016) {
      width = '1016'
    }
    if (parseInt(height) < 560) {
      height = '560'
    }

    let hasParams = true
    if (
      sellerId == null ||
      sellerId == undefined ||
      sellerId == 'null' ||
      sellerId.trim() == '' ||
      buyerId == null ||
      buyerId == undefined ||
      buyerId == 'null' ||
      buyerId.trim() == '' ||
      partnerId == null ||
      partnerId == undefined ||
      partnerId == 'null' ||
      partnerId.trim() == ''
    ) {
      hasParams = false
    }

    if (hasParams) {
      const goShoppingParams: IParsedGoShoppingParams = {
        sellerId,
        buyerId,
        partnerId,
        localInventory,
        width,
        height,
        useDataIsland,
        cartButton: cartButton as CartButtonValues,
        partsBasket: '',
        service,
        HOOK_URL: hookUrl,
        checkAvailabilityRedirect,
        aesServer,
      }

      if (partsBasket != null && partsBasket != undefined) {
        if (typeof partsBasket == 'string') {
          goShoppingParams.partsBasket = partsBasket
        } else {
          goShoppingParams.partsBasket = JSON.stringify(partsBasket)
        }
      }

      if (showHeader != null && showHeader != undefined) {
        if (typeof showHeader == 'string') {
          goShoppingParams.showHeader = showHeader
        } else {
          goShoppingParams.showHeader = JSON.stringify(showHeader)
        }
      } else {
        goShoppingParams.showHeader = ''
      }
      if (goShoppingUrl == null || goShoppingUrl == undefined) {
        goShoppingParams.goShoppingUrl =
          location.protocol + '//' + location.host + location.pathname
      }

      servletInit({ data: goShoppingParams }, resolve)
    } else {
      getCredentialsFromWebServer(resolve)
    }
  })
}

export default init
