import { Menu, MenuItem } from '@mui/material'
import { ReactElement } from 'react'
import styled from 'styled-components'

interface Props<T> {
  open: boolean
  parentRef: React.RefObject<HTMLElement>
  items: T[]
  onClose: () => void
}

const HiddenItemsMenu = <T,>(props: Props<T>): ReactElement => {
  const { open, parentRef, items, onClose } = props
  return (
    <StyledMenu
      open={open}
      onClick={onClose}
      onClose={onClose}
      anchorEl={parentRef?.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {items.map((i, idx) => (
        <MenuItem key={`hidden_menu_item_${idx}`}>{i}</MenuItem>
      ))}
    </StyledMenu>
  )
}

const StyledMenu = styled(Menu)`
  a {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left !important;
    color: black !important;
  }
  span {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left !important;
    color: black !important;
  }
`

export default HiddenItemsMenu
