import { breakpoints } from 'src/theme/breakpoints'
import { ReactElement } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

export const TopNavigationBar = observer((props): ReactElement => {
  const { children } = props

  return <Wrapper id="topNavigation">{children}</Wrapper>
})

const Wrapper = styled.div`
  height: 100px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    background-color: black;
    overflow: visible;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
  }
  @media print {
    display: none;
  }
`
