import { observer } from 'mobx-react-lite'
import { ReactElement, useMemo } from 'react'
import logoUrl from 'src/assets/images/logo.png'
import { Config, IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { useUserStore } from 'src/store/user/UserStore'
import AstHeader from './AstHeader'
import CartOnlyHeader from './CartOnlyHeader'
import Header from './Header'
import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'

const HeaderPerAppMode = observer((): ReactElement => {
  const isCartNormalMode = Config?.isNotCartOnlyMode
  const userStore = useUserStore()
  const userLogoUrl = useMemo(
    () => userStore.userLogo || logoUrl,
    [userStore.userLogo]
  )

  if (
    IS_INTEGRATED_VERSION &&
    EmbeddedCommunicationsManager.isAstPunchOut &&
    isCartNormalMode
  )
    return <AstHeader logoUrl={userLogoUrl} />
  if (isCartNormalMode) return <Header logoUrl={userLogoUrl} />
  return <CartOnlyHeader logoUrl={userLogoUrl} />
})

export default HeaderPerAppMode
