export const breakpoints = {
  mobileSmall: 320,
  mobileMedium: 375,
  mobileLarge: 500,
  mobileXLarge: 576,
  tablet: 768,
  tabletMedium: 933,
  tabletLarge: 993,
  tabletXLarge: 1016,
  laptop: 1024,
  laptopMedium: 1280,
  laptopLarge: 1440,
  laptopXLarge: 1920,
  laptop4k: 2560,
}

export const specialBreakpoints = {
  addVehicleButtonWrap: 1185,
  recentOrdersMobile: 475,
  recentOrdersTableColumnNameShift: 575,
  ddsAdvancedTrackerDesktop: 1260,
}
