import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LocalPhone,
} from '@mui/icons-material'
import { debounce, IconButton } from '@mui/material'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { Translate } from 'src/i18n'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useUserStore } from 'src/store/user/UserStore'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import impersonationImgDesktop from 'src/assets/images/tabler_ghost.png'
import impersonationImgMobile from 'src/assets/images/white_tabler_ghost.svg'
import { ProfileMenu } from './profileMenu'
import { Tooltip } from 'src/ui-components/modals/Tooltip'
import { ShoppingCartMenu } from '../CommonMenuItems'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { observer } from 'mobx-react-lite'

const iconStyle = { fontSize: 33, color: '#000' }
const iconStyleMobile = { fontSize: 25, color: '#fff' }
const ACCOUNT_MOBILE_HEADER_WIDTH = '72px' // the size of the account icon + the size of the arrow down

const AccountMenu = observer((): ReactElement => {
  const cart = useShoppingCart()
  const [showAccountMenu, setShowAccountMenu] = useState(false)
  const { setShowContactDrawer } = useUiStateStore()
  const userStore = useUserStore()
  const menuButton = useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [showAccountNameInfo, setShowAccountNameInfo] = useState(true)

  const contactUStooltip = Translate('contactUs')

  const handleResize = useCallback(() => {
    const width = menuButton?.current?.clientWidth
    setShowAccountNameInfo(width > 150)
  }, [])

  useEffect(() => {
    const resize = debounce(handleResize, 100)
    resize() // Initial call to set things up
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [handleResize])

  return (
    <Wrapper>
      <LocalPhoneIconButton
        aria-label="LocalPhone"
        onClick={() => setShowContactDrawer(true)}
      >
        <LocalPhone style={iconStyleMobile} />
      </LocalPhoneIconButton>

      <UserWrapper
        ref={menuButton}
        onClick={() => setShowAccountMenu(true)}
        width={!showAccountNameInfo ? ACCOUNT_MOBILE_HEADER_WIDTH : null}
      >
        {userStore.getIsImpersonating() ? (
          <>
            <ImpersonateImgMobile
              src={impersonationImgMobile}
              alt="user impersonating"
              className="d-lg-none"
            />
            <img
              src={impersonationImgDesktop}
              alt="user impersonating"
              className="d-none d-lg-block mx-2"
            />
          </>
        ) : (
          <AccountCircleIconButton aria-label="AccountCircle">
            <AccountCircleIcon style={iconStyle} />
            <AccountCircleIconMobile style={iconStyleMobile} />
          </AccountCircleIconButton>
        )}
        {showAccountNameInfo ? (
          <AccountInfo>
            <AccountNameWrapper>
              <UsernameText className="test-class">
                <span>{userStore?.preferences?.orgName} </span>
              </UsernameText>
              {!showAccountMenu ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </AccountNameWrapper>
            <OrganizationNameText>
              {userStore?.preferences?.screenName}
            </OrganizationNameText>
          </AccountInfo>
        ) : (
          <ExpandMoreWrapper>
            {!showAccountMenu ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </ExpandMoreWrapper>
        )}
      </UserWrapper>

      <LocalPhoneIconButtonForWeb
        aria-label="LocalPhoneWeb"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => setShowContactDrawer(true)}
      >
        <Tooltip
          message={contactUStooltip}
          show={showTooltip}
          placement="bottom"
        >
          <LocalPhone style={iconStyle} />
        </Tooltip>
      </LocalPhoneIconButtonForWeb>

      <ShoppingCartWrapper>
        <ShoppingCartMenu qty={cart.totalCartQty} />
      </ShoppingCartWrapper>

      <ProfileMenu
        onClose={() => setShowAccountMenu(false)}
        parentRef={menuButton}
        open={showAccountMenu}
        accountName={userStore?.preferences?.orgName}
      />
    </Wrapper>
  )
})

export default AccountMenu

const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  gap: 24px;
  //overflow: scroll;
`

const ExpandMoreWrapper = styled.div`
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 100px;
  }

  @media screen and (width <= ${breakpoints.tabletMedium}px) {
    display: none;
  }
`

const AccountNameWrapper = styled.div`
  width: 100%;
  display: flex;
`

const UsernameText = styled.span`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  max-width: 165px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const AccountCircleIconButton = styled(IconButton)`
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    padding: 8px 0px;
  }
`

const ImpersonateImgMobile = styled.img`
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    padding: 8px 0px;
  }
`

const AccountCircleIcon = styled(AccountCircle)`
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const AccountCircleIconMobile = styled(AccountCircle)`
  display: none;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: block;
  }
`

const LocalPhoneIconButton = styled(IconButton)`
  display: none;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: block;
    padding: 8px 0px;
  }
`

const OrganizationNameText = styled.div`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

interface UserWrapperProps {
  width?: string
}

const UserWrapper = styled.div<UserWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;

  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 261px;
  }

  @media screen and (${breakpoints.laptopLarge}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 261px;
    max-width: 261px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopLarge}px) {
    min-width: 200px;
    max-width: 261px;
  }

  @media screen and (width <= ${breakpoints.laptopMedium}px) {
    ${({ width }) => (width ? `width: ${width};` : `min-width: 100px;`)}
    max-width: 200px;
  }

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    width: fit-content;
  }
`

const LocalPhoneIconButtonForWeb = styled(IconButton)`
  width: 43px;
  padding: 8px 0px;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const ShoppingCartWrapper = styled.div`
  width: fit-content;
`
