import {
  IPartItemResponse,
  IdGroupsResponse,
  IntegrationsInfoResponse,
  PartTypeItem,
} from 'src/features/partsCatalog/Selections/interfaces'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import { PartsBasketCreds } from 'src/api/security/interfaces'
import { PartsCatalogAPI } from './PartsCatalogAPI'
import CatalogServiceProvider from 'src/services/CatalogServiceProvider'

export const defaultIntegrationInfo: IntegrationsInfoResponse = {
  epeFlag: true,
  allowAnyBuyer: false,
  authAttributesId: '',
}

export class AstPunchOutCatalogAPI extends PartsCatalogAPI {
  constructor() {
    super()
  }

  fetchBrands = async (): Promise<Array<IdValueGeneric<string, string>>> => {
    const resp = await CatalogServiceProvider.fetchAstBrands()

    const brands = resp.data?.map((c): IdValueGeneric<string, string> => {
      return { id: c.value, value: c.label }
    })

    return brands
  }

  fetchCategories = async (): Promise<
    Array<IdValueGeneric<string, string>>
  > => {
    const resp = await CatalogServiceProvider.fetchAstCategories()

    if (resp.status === 204) return []

    const categories = resp?.data?.map((c): IdValueGeneric<string, string> => {
      return { id: c.value, value: c.label }
    })

    return categories
  }

  fetchGroups = async (
    categories: Array<IdValueGeneric<string, string>>
  ): Promise<IdGroupsResponse> => {
    const category = categories?.[0]
    const endcodedCategory = encodeURIComponent(category.id)
    const resp = await CatalogServiceProvider.fetchAstGroups(endcodedCategory)

    const groupItems = resp.data?.map((g): PartTypeItem => {
      return {
        id: g.value,
        value: g.label,
        isConsolidated: true,
      }
    })

    return [
      {
        ...category,
        items: groupItems,
      },
    ]
  }

  fetchPartTypes = async (
    groups: Array<IdValueGeneric<string, string>>
  ): Promise<IPartItemResponse> => {
    const group = groups?.[0]
    const encodedGroup = encodeURIComponent(group.id)
    const resp =
      await CatalogServiceProvider.fetchAstTerminologies(encodedGroup)

    const partTypeItems = resp.data?.map((pt): PartTypeItem => {
      return {
        id: pt.value,
        value: pt.label,
        isConsolidated: true,
      }
    })

    return [
      {
        ...group,
        items: partTypeItems,
      },
    ]
  }

  fetchIntegrationsInfo = async (
    credentials: PartsBasketCreds
  ): Promise<IntegrationsInfoResponse> => {
    const parameters = {
      partnerId: credentials.partnerId,
      sellerId: credentials.sellerId,
      buyerId: credentials.buyerId,
    }

    try {
      const resp =
        await CatalogServiceProvider.fetchAstIntegrationInfo(parameters)

      if (resp?.status === 204) {
        return defaultIntegrationInfo
      }
      return resp?.data
    } catch (_e) {
      return defaultIntegrationInfo
    }
  }

  isGfxSupported = (): boolean => {
    return false
  }
}

export default new AstPunchOutCatalogAPI()
