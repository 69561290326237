import { StorageKeys } from 'src/config/constants'
import { CxmlDetails } from 'src/store/models/ShoppingCartModels'

export const getCxmlDetails = (): CxmlDetails => {
  const cxmlDetails =
    localStorage.getItem(StorageKeys.CXMLDETAILS_KEY) ||
    '{"cxmlData":false,"token":""}'
  try {
    return JSON.parse(cxmlDetails)
  } catch (_error) {
    return { cxmlData: false, token: '' }
  }
}
