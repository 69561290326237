import CloseIcon from '@mui/icons-material/Close'
import { Drawer, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { I18n, Translate } from 'src/i18n'
import ListServiceProvider from 'src/services/ListServiceProvider'
import { useListsStore } from 'src/store/lists/ListsStore'
import { Category, GroupPart } from 'src/store/lists/interfaces'
import { IdNamePair, IdValuePair } from 'src/store/models/KeyValuePair'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { defaultTheme } from 'src/theme'
import { zIndex } from 'src/theme/zIndex'
import { Button } from 'src/ui-components'
import styled from 'styled-components'

export const MovePartsSelectionDrawer = observer((): ReactElement => {
  const { showMoveToNextListDrawer, setMoveToNextListDrawer } =
    useUiStateStore()

  const ref = useRef<HTMLInputElement>(null)

  const {
    selectedItemsList,
    getListGroups,
    moveListGroups,
    onMoveGroupParts,
    selectedGroup,
    setShowBottomDrawer,
    resetSelectedPartsList,
  } = useListsStore()

  const [moveListGroupsOptions, setMoveListGroupsOptions] = useState<
    IdNamePair<number, string>[] | null
  >(null)

  const [categories, setCategories] = useState<Category[] | null>(null)
  const [lists, setLists] = useState<IdValuePair<string>[] | null>(null)

  const [category, setCategory] = useState('')
  const [list, setList] = useState('')
  const [group, setGroup] = useState('')

  useEffect(() => {
    const moveListGroupsOptionsFiltered = moveListGroups.filter(
      (eachGroup) => selectedGroup.id !== eachGroup.id
    )
    setMoveListGroupsOptions(moveListGroupsOptionsFiltered)
  }, [moveListGroups, selectedGroup])

  useEffect(() => {
    setCategory('')
    setList('')
    setGroup('')
    setLists(null)
    setMoveListGroupsOptions(null)
  }, [showMoveToNextListDrawer])

  useEffect(() => {
    const getCategories = async () => {
      const { categories } = await ListServiceProvider.getCategoriesAndLists()
      setCategories(categories)
    }

    getCategories()
  }, [])

  const onCategoryChange = (event: SelectChangeEvent) => {
    setList('')
    setGroup('')
    setCategory(event.target.value as string)

    const filteredCategory = categories.find((categoryItem) => {
      return categoryItem.id.toString() === event.target.value.toString()
    })

    const filteredLists = filteredCategory.lists.map((listItem) => {
      return { id: listItem.id.toString(), value: listItem.name.toString() }
    })

    setLists(filteredLists)
  }
  const onListChange = (event: SelectChangeEvent) => {
    setGroup('')
    setList(event.target.value as string)

    getListGroups(parseInt(category, 10), parseInt(event.target.value, 10))
  }
  const onGroupChange = (event: SelectChangeEvent) => {
    setGroup(event.target.value as string)
  }

  const moveItems = selectedItemsList?.filter(
    (item: GroupPart) => item.isSelected
  )

  const handleClose = (): void => {
    setMoveToNextListDrawer(false)
  }

  const handleMoveGroupParts = () => {
    setMoveToNextListDrawer(false)
    const movingGroupPartsIds = moveItems.map((part: GroupPart) => {
      return part.id
    })
    resetSelectedPartsList(movingGroupPartsIds)
    setShowBottomDrawer(false)
    onMoveGroupParts(parseInt(group, 10), movingGroupPartsIds)
  }

  return (
    <Drawer
      anchor="right"
      open={showMoveToNextListDrawer}
      onClose={() => setMoveToNextListDrawer(false)}
      style={{ zIndex: zIndex.modal }}
      PaperProps={{
        sx: { width: '30%' },
      }}
    >
      <Container ref={ref}>
        <HeaderWrapper>
          <I18n
            name={`${Translate('moveToAnotherList')} (${
              moveItems?.length
            } ${Translate('items')})`}
          />
          <StyledCloseIcon onClick={handleClose} />
        </HeaderWrapper>

        <SelectContainer>
          <SelectWrapper>
            <I18n
              name="category"
              style={{ fontWeight: 'bold', fontSize: '14px' }}
            />

            <Select
              id="category-select"
              value={category}
              onChange={onCategoryChange}
              MenuProps={{
                style: { zIndex: zIndex.modal },
              }}
              displayEmpty
              renderValue={
                category !== '' ? undefined : () => 'Select a Category'
              }
              label=""
              style={{ marginTop: '10px' }}
            >
              {categories?.map((eachCategory) => (
                <MenuItem
                  key={eachCategory.id}
                  value={eachCategory.id}
                  style={{ fontWeight: 'bold' }}
                >
                  {eachCategory.name}
                </MenuItem>
              ))}
            </Select>
          </SelectWrapper>

          <SelectWrapper>
            <I18n
              name="list"
              style={{ fontWeight: 'bold', fontSize: '14px' }}
            />

            <Select
              id="list-select"
              value={list}
              onChange={onListChange}
              MenuProps={{
                style: { zIndex: zIndex.modal },
              }}
              displayEmpty
              renderValue={list !== '' ? undefined : () => 'Select a List'}
              style={{ marginTop: '10px' }}
            >
              {lists?.map((eachList) => (
                <MenuItem
                  key={eachList.id}
                  value={eachList.id}
                  style={{ fontWeight: 'bold' }}
                >
                  {eachList.value}
                </MenuItem>
              ))}
            </Select>
          </SelectWrapper>

          <SelectWrapper>
            <I18n
              name="group"
              style={{ fontWeight: 'bold', fontSize: '14px' }}
            />

            <Select
              id="group-select"
              value={group}
              onChange={onGroupChange}
              MenuProps={{
                style: { zIndex: zIndex.modal },
              }}
              displayEmpty
              renderValue={group !== '' ? undefined : () => 'Select a Group'}
              style={{ marginTop: '10px' }}
            >
              {moveListGroupsOptions?.map(
                (eachList: IdNamePair<number, string>) => (
                  <MenuItem
                    key={eachList.id}
                    value={eachList.id}
                    style={{ fontWeight: 'bold' }}
                  >
                    {eachList.name}
                  </MenuItem>
                )
              )}
            </Select>
          </SelectWrapper>
        </SelectContainer>

        <BottomContainer>
          <ButtonsContainer>
            <Button
              text="cancel"
              onClick={() => {
                setMoveToNextListDrawer(false)
              }}
              variant="secondary"
            />
            <Button
              text={`${Translate('move')} ${moveItems?.length} ${Translate(
                'part'
              )}`}
              onClick={handleMoveGroupParts}
              variant="primary"
              disabled={group === ''}
            />
          </ButtonsContainer>
        </BottomContainer>
      </Container>
    </Drawer>
  )
})

const Container = styled.div`
  width: 100%;
  background-color: ${defaultTheme.colors.white};
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: 'row';
  border-bottom: 1px solid silver;
  display: flex;
  align-items: center;
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  padding: 10px 20px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 10px 0px;
  align-items: center;
`

const BottomContainer = styled.div`
  height: 50px;
  width: 94%;
  position: absolute;
  bottom: 0;
  background-color: ${defaultTheme.colors.white};
  justify-content: end;
  align-items: center;
  display: flex;
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 20px;
`

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`
