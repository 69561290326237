import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { I18n, Translate } from 'src/i18n'
import { useSearchStore } from 'src/store/search/SearchStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { defaultTheme } from 'src/theme'
import { zIndex } from 'src/theme/zIndex'
import { Button } from 'src/ui-components'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'
import { CSVFileParser } from 'src/ui-components/util/CSVFileParser'
import styled from 'styled-components'
import { MultiPartInquiry, MultiPartLinesData } from '../interfaces'

import DoneIcon from '@mui/icons-material/Done'
import WarningIcon from '@mui/icons-material/Warning'

export const MultiPartInquiryImportDrawer = observer((): ReactElement => {
  const {
    showMultiPartInquiryImportDrawer,
    setShowMultiPartInquiryImportDrawer,
    displaySuccessNotification,
  } = useUiStateStore()
  const { searchMultiPartInquiry } = useSearchStore()
  const history = useHistory()
  const [importButtonDisabled, setImportButtonDisabled] = useState(true)
  const [showLinesData, setShowLinesData] = useState(false)
  const [importMultiPartsSearch, setImportMultiPartsSearch] = useState<
    Array<MultiPartInquiry>
  >([])
  const [multiPartsLinesData, setMultiPartsLinesData] =
    useState<MultiPartLinesData>({})

  const drawerBody = (
    <Container>
      <I18n name="importListDescription" />
      <a
        href="/csv/PARTINQUIRY_TEMPLATE.csv"
        style={{ marginTop: '15px', color: defaultTheme.colors.link }}
      >
        <I18n name="useTemplateCsv" />
      </a>
      {!showLinesData ? (
        <FileContainer>
          <CSVFileParser
            setImportButtonDisabled={setImportButtonDisabled}
            setImportMultiPartsSearch={setImportMultiPartsSearch}
            setMultiPartsLinesData={setMultiPartsLinesData}
          />
        </FileContainer>
      ) : (
        <LinesDataContainer>
          <DoneIcon style={{ color: defaultTheme.colors.green, scale: '2' }} />
          <I18n
            name={Translate('listImportedSuccessfully', [
              multiPartsLinesData?.successLines || '',
            ])}
            style={{
              fontSize: '16px',
              marginTop: '15px',
              fontWeight: 'bold',
            }}
          />
          {multiPartsLinesData.errorLines &&
          multiPartsLinesData.errorLines > 0 ? (
            <ErrorContainer>
              <WarningIcon
                style={{
                  color: defaultTheme.colors.red,
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
              <I18n
                name={Translate('listImportedError', [
                  multiPartsLinesData.errorLines,
                ])}
                style={{ fontSize: '16px' }}
              />
            </ErrorContainer>
          ) : null}
        </LinesDataContainer>
      )}
    </Container>
  )

  const drawerFooter = (
    <ButtonsWrapper>
      <Button
        text="cancel"
        onClick={() => {
          setShowMultiPartInquiryImportDrawer(false)
        }}
        variant="secondary"
      />

      {!showLinesData ? (
        <Button
          text="import"
          onClick={() => {
            if (importMultiPartsSearch?.length > 0) {
              setShowLinesData(true)
            }
          }}
          variant="primary"
          disabled={importButtonDisabled}
        />
      ) : (
        <Button
          text="finish"
          onClick={() => {
            searchMultiPartInquiry(importMultiPartsSearch)
            history.push('/searchResults')
            setShowMultiPartInquiryImportDrawer(false)
            displaySuccessNotification('listImported')
          }}
          variant="primary"
        />
      )}
    </ButtonsWrapper>
  )

  const resetFileSelection = useCallback(() => {
    setImportButtonDisabled(true)
    setImportMultiPartsSearch([])
    setMultiPartsLinesData({})
    setShowLinesData(false)
  }, [])

  useEffect(() => {
    if (showMultiPartInquiryImportDrawer) {
      resetFileSelection()
    }
  }, [resetFileSelection, showMultiPartInquiryImportDrawer])

  return (
    <BaseDrawer
      open={showMultiPartInquiryImportDrawer}
      title="importList"
      body={drawerBody}
      onClose={() => {
        setShowMultiPartInquiryImportDrawer(false)
      }}
      width="600px"
      footer={drawerFooter}
    />
  )
})

const ButtonsWrapper = styled.div`
  justify-content: end;
  align-items: end;
  display: flex;
  margin-top: 16px;
`

const Container = styled.div`
  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
  display: flex;
  flex-direction: column;
`

const FileContainer = styled.div`
  border-style: dashed;
  border-color: lightgrey;
  border-radius: 5px;
  margin-top: 15px;
  padding: 15px;
`

const LinesDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding: 15px;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 5px;
  border: 1px solid silver;
  padding: 10px;
  margin-top: 15px;
`
