import { ArrowBack, Close as CloseIcon } from '@mui/icons-material'
import { Box, Drawer } from '@mui/material'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { ReactElement, useMemo, useRef } from 'react'
import { I18n } from 'src/i18n'
import { AddGroupPart, ListResponseError } from 'src/store/lists/interfaces'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import { zIndex } from 'src/theme/zIndex'
import styled from 'styled-components'
import { AddProductsToGroupTable } from './AddProductsToGroupTable'
import { create as createNiceModal, useModal } from '@ebay/nice-modal-react'
import ListServiceProvider from 'src/services/ListServiceProvider'
import ListNode from 'src/store/lists/ListNode'
import { AxiosError } from 'axios'

const validateLengthIntervalRequired = (
  value,
  rowHasEntries = false,
  min: number,
  max: number
) => {
  if ((value === undefined || value === '') && rowHasEntries) {
    return 'Required'
  }
  if ((value?.length < min || value?.length > max) && rowHasEntries) {
    return `Min ${min} and max ${max} characters`
  }
  return undefined
}

const validateNumberIntervalRequired = (
  value,
  rowHasEntries = false,
  min: number,
  max: number
) => {
  if ((value === undefined || value === '') && rowHasEntries) {
    return 'Required'
  }
  if ((value < min || value > max) && rowHasEntries) {
    return `Min ${min} and max ${max} characters`
  }
  return undefined
}

const makeRow = (): AddGroupPart => {
  return {
    lineCode: '',
    partNumber: '',
    stockQty: 1,
    comment: '',
  }
}

interface IAddProductsToGroup {
  categoryLabel: string
  listLabel: string
  selectedGroup: ListNode
  selectedList: ListNode
  getListGroupParts
}

export const AddProductsToGroup = createNiceModal<IAddProductsToGroup>(
  observer(
    ({
      categoryLabel,
      listLabel,
      selectedGroup,
      selectedList,
      getListGroupParts,
    }): ReactElement => {
      const modal = useModal()
      const ref = useRef<HTMLInputElement>()

      const initialValues: Array<AddGroupPart> = useMemo(
        () => [...Array(5)].map(makeRow),
        []
      )

      const handleOnClose = () => {
        modal.remove()
      }

      const addPartToGroup = async (
        req: Array<AddGroupPart>
      ): Promise<void> => {
        try {
          const status = await ListServiceProvider.addPartToGroup(
            selectedList.id,
            selectedGroup.id,
            req
          )
          if (status === 200 || status === 206) {
            StoreInstances.uiStore.displaySuccessNotification(
              'Successfully added item to group'
            )
            handleOnClose()
            getListGroupParts()
          }
        } catch (error) {
          const { message }: ListResponseError = (error as AxiosError).response
            .data as ListResponseError
          StoreInstances.uiStore.displayErrorNotification(message)
        }
      }

      const isTablet = window.innerWidth <= breakpoints.tablet

      return (
        <Drawer
          anchor={isTablet ? 'bottom' : 'right'}
          onClose={handleOnClose}
          open={modal.visible}
          style={{ width: isTablet ? '100%' : '814px' }}
        >
          <Container ref={ref}>
            <Formik
              initialValues={{
                items: initialValues,
              }}
              onSubmit={(notTrimmedValues) => {
                const values = notTrimmedValues.items.map((item) => {
                  return {
                    lineCode: item.lineCode.trim().toUpperCase(),
                    partNumber: item.partNumber.trim(),
                    stockQty: item.stockQty,
                    comment: item.comment.trim(),
                  }
                })
                const validation = values.every(
                  (item) => item.partNumber === ''
                )
                if (validation) {
                  StoreInstances.uiStore.displayErrorNotification(
                    'Add atleast one item to list'
                  )
                } else {
                  const partsToAdd = values.filter(
                    (item) => item.partNumber !== ''
                  )

                  addPartToGroup(partsToAdd)
                }
              }}
              render={({ values }) => {
                const lastItem = values?.items[values?.items?.length - 1]
                if (
                  !!lastItem?.partNumber ||
                  lastItem?.stockQty !== 1 ||
                  !!lastItem?.comment
                ) {
                  values.items.push(makeRow())
                }
                return (
                  <Form>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="column"
                      height="100vh"
                      style={{ padding: isTablet ? '0px' : '24px' }}
                    >
                      <div>
                        <TitleHeader>
                          <div style={{ display: 'flex' }}>
                            <StyledBackIcon onClick={handleOnClose} />
                            <p>
                              <I18n name="addItemsToGroup" />
                            </p>
                          </div>

                          <StyledCloseIcon onClick={handleOnClose} />
                        </TitleHeader>
                        {/* @TODO add the correct ones */}
                        <Breadcrumb>
                          <BreadcrumbText>
                            {categoryLabel}
                            {'\u00a0'}
                          </BreadcrumbText>
                          <Divider>|</Divider>

                          <BreadcrumbText>
                            {'\u00a0'}
                            {listLabel}
                            {'\u00a0'}
                          </BreadcrumbText>
                          <Divider>|</Divider>

                          <BreadcrumbText>
                            {'\u00a0'}
                            {selectedGroup?.value}
                          </BreadcrumbText>
                        </Breadcrumb>
                        <HeaderRow>
                          <ColHeader width="15%">
                            <I18n name="MFR" />
                          </ColHeader>
                          <ColHeader width="34%">
                            <I18n name="PART #" />
                          </ColHeader>
                          <ColHeader width="12%">
                            <I18n name="QTY" />
                          </ColHeader>
                          <ColHeader width="37%">
                            <I18n name="COMMENTS" />
                          </ColHeader>
                          <ColHeader width="5%" />
                        </HeaderRow>
                      </div>

                      <AddProductsToGroupTable
                        values={values}
                        validateNumberIntervalRequired={
                          validateNumberIntervalRequired
                        }
                        validateLengthIntervalRequired={
                          validateLengthIntervalRequired
                        }
                      />

                      <ButtonContainer>
                        <ButtonCancel type="button" onClick={handleOnClose}>
                          <I18n name="cancel" />
                        </ButtonCancel>
                        <Button type="submit">
                          <ButtonContent>
                            <MoveText>
                              <I18n name="addItems" />
                            </MoveText>
                          </ButtonContent>
                        </Button>
                      </ButtonContainer>
                    </Box>
                  </Form>
                )
              }}
            />
          </Container>
        </Drawer>
      )
    }
  )
)

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 12px 24px;
  gap: 9px;
  width: 98%;
  height: 48px;
  background: ${defaultTheme.colors.primary};
  border-radius: 3px;
  border: none;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    height: 36px;
    width: 112px;
    flex-direction: row;
    gap: 0px;
    padding: 0px;
    align-items: center;
    margin: 0px;
  }
`
const MoveText = styled.span`
  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: relative;
    top: 1px;
  }
  color: ${defaultTheme.colors.primaryText};
`

const ButtonContent = styled.h6`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
  text-transform: uppercase;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin: 0px;
  }
`

const ButtonCancel = styled(Button)`
  background-color: white;
  border-radius: 3px;
  border: 1px solid ${defaultTheme.colors.primaryText};
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${defaultTheme.colors.primaryText};
  margin-top: 16px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin: 0px;
    width: 91px;
    height: 36px;
  }

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);

  @media screen and (min-width: ${breakpoints.laptop}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-shadow: none;
    gap: 16px;
    margin-top: 47px;
  }

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    background: #ffffff;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.04);
    height: 96px;
    margin-bottom: 56px;
    width: 100%;

    button {
      width: 342px;
    }
  }
`

const BreadcrumbText = styled.span`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${defaultTheme.colors.primaryText};
`

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const Divider = styled.span`
  color: ${defaultTheme.colors.borderTable};
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    align-self: center;
  }
`

const StyledBackIcon = styled(ArrowBack)`
  display: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  align-self: center;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    margin-right: 8px;
  }
`

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 48px;
  color: ${defaultTheme.colors.almostBlack};
  padding-top: 24px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 24px;
    font-family: Oswald;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  p {
    margin: 0px;
  }
`

const HeaderRow = styled.div`
  background: ${defaultTheme.colors.rowFeaturedBackground};
  box-shadow:
    inset 0px -1px 0px ${defaultTheme.colors.borderTable},
    inset 0px 1px 0px ${defaultTheme.colors.borderTable};

  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

interface HeaderRowProps {
  width?: string
}
const ColHeader = styled.div<HeaderRowProps>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: ${(p) => p.width};
  color: ${defaultTheme.colors.columnBorder};
  padding-right: 24px;
`
const Container = styled.div`
  width: 814px;
  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
  }
`
