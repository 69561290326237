import { IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { ReactElement, useEffect, useState } from 'react'
import { GroupPart, UpdateGroupPart } from 'src/store/lists/interfaces'
import { useListsStore } from 'src/store/lists/ListsStore'
import { defaultTheme } from 'src/theme'
import { Checkbox, Thumbnail } from 'src/ui-components'
import styled from 'styled-components'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'

import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import { Close } from '@mui/icons-material'
import { Translate } from 'src/i18n'
import { SimpleDeleteModal } from './SimpleDeleteModal'
import { dateOptions, DateValidation } from '../interfaces'
import { breakpoints } from 'src/theme/breakpoints'
import { toUTCFromDateAndFormat } from 'src/helpers/date'
import { Sorting } from 'src/ui-components/util/Interfaces'

interface ListGroupPartRowProps {
  part: GroupPart
  isSelected: boolean
  noBorderBottom?: boolean
  thumbnail?: string
}

export const ListGroupPartRow = observer(
  (props: ListGroupPartRowProps): ReactElement => {
    const { part, isSelected, noBorderBottom, thumbnail } = props
    const {
      onDeleteGroupPart,
      getSelectedCategoryLabel,
      updatePartQty,
      updatePartInGroup,
      onSelectGroupPart,
    } = useListsStore()
    const [disableEditing, setDisableEditing] = useState(true)

    const [mfr, setMfr] = useState(part?.lineCode)
    const [partNo, setPartNo] = useState<string | number>(part?.partNumber)
    const [qty, setQty] = useState<number>(part?.stockQty)
    const [comment, setComment] = useState(part?.comment)
    const [commentCount, setCommentCount] = useState(
      comment === null || undefined ? 0 : comment?.length
    )

    const [checkBoxSelected, setCheckBoxSelected] = useState(isSelected)

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    // menu
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
    const openMenu = Boolean(menuAnchorEl)

    const { setMoveToAnotherListDrawer } = useUiStateStore()

    const isSellerManagedCategory =
      getSelectedCategoryLabel() === Sorting.SELLER_MANAGED_LIST_LABEL

    const mfrFieldValidations = {
      minLength: 2,
      maxLength: 3,
    }

    useEffect(() => {
      setCheckBoxSelected(isSelected)
    }, [isSelected])

    useEffect(() => {
      if (checkBoxSelected) {
        onSelectGroupPart(part.id, true)
      } else {
        onSelectGroupPart(part.id, false)
      }
    }, [checkBoxSelected, part.id, onSelectGroupPart])

    const handleMenuClose = () => {
      setMenuAnchorEl(null)
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget)
    }

    const handleDeleteModal = () => {
      setShowDeleteModal(!showDeleteModal)
    }

    const handleDeleteAction = () => {
      onDeleteGroupPart([part.id])
      setShowDeleteModal(!showDeleteModal)
    }

    const handleUpdatePart = () => {
      if (Number.isNaN(qty)) {
        setQty(1)
      }

      if (
        mfr.length < mfrFieldValidations.minLength ||
        mfr.length > mfrFieldValidations.maxLength
      ) {
        StoreInstances.uiStore.displayErrorNotification(
          'mfrValidationMessage',
          null,
          null,
          [mfrFieldValidations.minLength, mfrFieldValidations.maxLength]
        )
      } else if (partNo.toString() === '') {
        StoreInstances.uiStore.displayErrorNotification('partNumberIsEmpty')
      } else {
        const updatePart: UpdateGroupPart = {
          id: part.id,
          lineCode: mfr,
          partNumber: partNo.toString(),
          stockQty: qty,
          comment,
        }
        updatePartInGroup([updatePart])
          .then(() => updatePartDetails(updatePart))
          .catch(() => cancelPartUpdate())
      }
    }

    const updatePartDetails = (updatePart: UpdateGroupPart) => {
      setMfr(updatePart?.lineCode)
      setPartNo(updatePart?.partNumber)
      setQty(updatePart?.stockQty)
      setComment(updatePart?.comment)
      setCommentCount(updatePart?.comment?.length)
      setDisableEditing(!disableEditing)
    }

    const cancelPartUpdate = () => {
      setMfr(part?.lineCode)
      setPartNo(part?.partNumber)
      setQty(part?.stockQty)
      setComment(part?.comment)
      setCommentCount(part?.comment?.length)
      setDisableEditing(!disableEditing)
    }

    const lastUsedOnDate = new Date(part?.lastUsedOn)

    return (
      <>
        <Row checked={checkBoxSelected} noBorder={noBorderBottom}>
          <>
            <TCell
              style={{
                width: '60px',
                paddingLeft: '25px',
              }}
            >
              <Checkbox
                value=""
                label=""
                active={checkBoxSelected}
                onClick={() => setCheckBoxSelected(!checkBoxSelected)}
              />
            </TCell>
            <TCell
              style={{
                width: '120px',
              }}
            >
              <Thumbnail
                urlOrBase64={thumbnail}
                width={90}
                height={90}
                placeholder
              />
            </TCell>
            <TCell
              style={{
                width: '100px',
              }}
            >
              <StyledInput
                value={mfr}
                onChange={(e) => setMfr(e.target.value.toUpperCase())}
                disabled={disableEditing}
                style={{ width: '80%' }}
              />
            </TCell>

            <TCell style={{ width: '15%' }}>
              <StyledTextarea
                value={partNo}
                onChange={(e) => {
                  setPartNo(e.target.value)
                }}
                disabled={disableEditing}
                maxLength={20}
                style={{ borderRadius: '5px', height: '45px' }}
                enablePadding
              />
            </TCell>

            <TCell style={{ width: '90px' }}>
              <StyledInput
                style={{ width: '60px' }}
                value={qty?.toString()}
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    setQty(999)
                  } else {
                    setQty(e.target.valueAsNumber)
                  }
                }}
                onBlur={() => {
                  updatePartQty(qty, part.id, part.isSelected)
                }}
                type="number"
              />
            </TCell>
            <TCell style={{ width: '15%' }}>
              <StyledText>{part.description}</StyledText>
            </TCell>
            <TCell style={{ width: '15%' }}>
              <TextareaContainer>
                <StyledTextarea
                  rows={3}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value)
                    setCommentCount(e.target.value.length)
                  }}
                  disabled={disableEditing}
                  maxLength={45}
                />
                {!disableEditing ? (
                  <StyledText
                    style={{
                      textAlign: 'end',
                      marginRight: '15px',
                      color: 'grey',
                    }}
                  >{`${commentCount}/45`}</StyledText>
                ) : null}
              </TextareaContainer>
            </TCell>
            <TCell>
              {lastUsedOnDate.toString() !== DateValidation.invalidDate && (
                <>
                  <StyledText>
                    {lastUsedOnDate.toLocaleDateString('en-US', dateOptions)}
                  </StyledText>
                  <StyledText
                    style={{
                      marginTop: '5px',
                      color: defaultTheme.colors.greyDark,
                    }}
                  >
                    {toUTCFromDateAndFormat(lastUsedOnDate)}
                    {'\u00a0'}
                    {Intl.DateTimeFormat().resolvedOptions().timeZoneName}
                  </StyledText>
                </>
              )}
            </TCell>
            <TCell style={{ width: '90px' }}>
              {disableEditing ? (
                <OptionsContainer>
                  <IconButton
                    onClick={() => setDisableEditing(!disableEditing)}
                    disabled={checkBoxSelected}
                    disableRipple
                    title={Translate('edit')}
                  >
                    <StyledEditIcon disabled={checkBoxSelected} />
                  </IconButton>
                  <IconButton
                    onClick={handleMenuClick}
                    title={Translate('moreOptions')}
                  >
                    <StyledMoreVertIcon />
                  </IconButton>
                </OptionsContainer>
              ) : (
                <OptionsContainer>
                  <StyledCloseIcon onClick={cancelPartUpdate} />
                  <StyledDoneIcon onClick={handleUpdatePart} />
                </OptionsContainer>
              )}

              <Menu
                style={{ marginTop: '40px' }}
                anchorEl={menuAnchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                elevation={6}
              >
                <MenuItem
                  onClick={() => {
                    if (!checkBoxSelected) {
                      setCheckBoxSelected(true)
                    }
                    setMoveToAnotherListDrawer(true)
                    handleMenuClose()
                  }}
                  disabled={isSellerManagedCategory}
                >
                  <ListItemIcon>
                    <DriveFileMoveIcon fontSize="small" />
                  </ListItemIcon>
                  <StyledMenuItem onClick={() => undefined}>
                    {Translate('move')}
                  </StyledMenuItem>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose()
                    handleDeleteModal()
                  }}
                  disabled={isSellerManagedCategory}
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <StyledMenuItem>{Translate('delete')}</StyledMenuItem>
                </MenuItem>
              </Menu>
            </TCell>
            <SimpleDeleteModal
              modalName="part"
              showDeleteModal={showDeleteModal}
              handleDeleteModal={handleDeleteModal}
              handleDeleteAction={handleDeleteAction}
            />
          </>
        </Row>
        <RowMobile checked={checkBoxSelected} noBorder={noBorderBottom}>
          <Mobile>
            <TCell
              style={{
                width: '60px',
                paddingLeft: '25px',
                paddingRight: '25px',
              }}
            >
              <Checkbox
                value=""
                label=""
                active={checkBoxSelected}
                onClick={() => setCheckBoxSelected(!checkBoxSelected)}
              />
            </TCell>
            <TCell
              style={{
                width: '140px',
                marginLeft: '6px',
              }}
            >
              <Thumbnail
                urlOrBase64={thumbnail}
                width={90}
                height={90}
                placeholder
              />
            </TCell>

            <MobileTc>
              <h6>{part.description}</h6>
              <p style={{ marginTop: '10px' }}>
                Part:<span>{part.partNumber}</span>
              </p>
              <p>
                Brand:<span>{mfr}</span>
              </p>
            </MobileTc>

            <QuantityWrapper>
              <p>
                Qty:<span>{part.stockQty}</span>
              </p>
            </QuantityWrapper>
          </Mobile>
        </RowMobile>
      </>
    )
  }
)

const Row = styled.tr<{ checked: boolean; noBorder?: boolean }>`
  width: 100%;
  box-shadow: ${(p) =>
    p.noBorder
      ? 'none'
      : `inset 0px -1px 0px ${defaultTheme.colors.greyMedium}`};
  background-color: ${(p) =>
    p.checked ? defaultTheme.colors.primaryFadeAlt : 'inherit'};
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`
const RowMobile = styled(Row)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
  }
`

const TCell = styled.td`
  text-align: left;
  vertical-align: top;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 15px 0;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 12px 0;
  }
`

interface InputProps {
  disabled?: boolean
  enablePadding?: boolean
}
const StyledInput = styled.input<InputProps>`
  display: inline-block;
  border: ${(p) => (p.disabled ? 'none' : '1px solid black')};
  padding: ${(p) => (p.disabled ? '' : '10px 15px')};
  border-radius: 4px;
  caret-color: ${(p) => p.theme.colors.primary};
  background-color: ${(p) => (p.disabled ? 'transparent' : 'white')};
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledTextarea = styled.textarea<InputProps>`
  resize: none;
  border: ${(p) => (p.disabled ? 'none' : '1px solid black')};
  background-color: ${(p) => (p.disabled ? 'transparent' : 'white')};
  margin-right: 15px;
  color: black;
  font-size: 13px;
  font-family: Roboto, serif;
  padding-top: ${(p) => (p.enablePadding && !p.disabled ? '13px' : '5px')};
`
const StyledText = styled.span`
  font-size: 13px;
  font-family: Roboto, serif;
  color: black;
  display: block;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 5px;
`
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`
const StyledEditIcon = styled(EditIcon)<{ disabled: boolean }>`
  color: ${(p) => (p.disabled ? defaultTheme.colors.disabledButton : 'grey')}
  margin-left: 10px;
  margin-right: 10px;
  &:hover {
    color: black;
    cursor: pointer;
  }
`
const StyledMoreVertIcon = styled(MoreVertIcon)`
  color: gray;
  &:hover {
    color: black;
    cursor: pointer;
  }
`
const StyledCloseIcon = styled(Close)`
  color: gray;
  border-radius: 2px;
  &:hover {
    color: black;
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.primary};
  }
`
const StyledDoneIcon = styled(DoneIcon)`
  color: gray;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 2px;
  &:hover {
    color: black;
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.primary};
  }
`
const StyledMenuItem = styled.div``

const Mobile = styled.div`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
  }
`
const MobileTc = styled(TCell)`
  width: 100%;
  margin-left: 12px;

  h6 {
    color: var(--black, #000);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0px;
  }
  p {
    color: #6b6b6b;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0px 0px 4px 0px;
    span {
      margin-left: 2px;
    }
  }
`

const QuantityWrapper = styled.div`
  width: fit-content;
  margin-right: 16px;
  p {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 12px 0px 0px 0px;

    span {
      margin-left: 2px;
    }
  }
`
