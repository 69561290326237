import { ReactElement } from 'react'
import styled from 'styled-components'
import { Icon } from '@mui/material'
import { Error, CheckCircle, CheckCircleOutline } from '@mui/icons-material'
import { I18n } from '../../../../i18n'
import { defaultTheme } from 'src/theme'

interface VinCheckMessageProps {
  vin: string
}

export const VinCheckMessage = (props: VinCheckMessageProps): ReactElement => {
  const { vin } = props

  const numChars = vin.length
  let textColor = defaultTheme.colors.greyDark
  let icon = <CheckCircleOutline htmlColor={textColor} fontSize="small" />
  if (numChars === 17) {
    textColor = defaultTheme.colors.success
    icon = <CheckCircle htmlColor={textColor} fontSize="small" />
  } else if (numChars > 0) {
    textColor = defaultTheme.colors.red
    icon = <Error htmlColor={textColor} fontSize="small" />
  }

  return (
    <Wrapper>
      <CircleCheckIcon $textColor={textColor}>{icon}</CircleCheckIcon>
      <I18n name="vinMustContain17Characters" style={{ color: textColor }} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: -5px; // accounting for icon margin
`

interface CircleCheckIconProps {
  $textColor: string
}

const CircleCheckIcon = styled(Icon)<CircleCheckIconProps>`
  color: ${(p) => p.$textColor};
  margin-right: 2px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
