export const navLinkStyle = {
  textDecoration: 'none',
}

export const activeStyle = {
  borderBottom: '4px solid #F9C100',
}

export const navTextStyle = {
  fontFamily: 'Oswald',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '34px',
  color: '#ffffff',
  cursor: 'pointer',
  textWrap: 'nowrap',
}
