import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Translate } from 'src/i18n'
import styled from 'styled-components'

import { defaultTheme } from 'src/theme'

import { ChevronRight } from '@mui/icons-material'
import { Box, LinearProgress } from '@mui/material'
import NoResults from 'src/features/search/Results/NoResults'
import { useListsStore } from 'src/store/lists/ListsStore'
import { useHistory } from 'react-router-dom'
import { BottomDrawer } from './BottomDrawer'
import { CartEditItemDrawer } from './CartEditItemDrawer'
import { ListGroupPartRow } from './ListGroupPartRow'
import { MoveGroupPartsDrawer } from './MoveGroupPartsDrawer'
import { MovePartsSelectionDrawer } from './MovePartsSelectionDrawer'
import { MultiSearchTableHeader } from './MultiSearchTableHeader'
import { GroupPart } from 'src/store/lists/interfaces'

export const MultiSearchTable = observer((): React.ReactElement => {
  const {
    getMultiSearchSelectedParts,
    setShowBottomDrawer,
    setSelectedItems,
    setShowSelectedItemsList,
    matchingFilteredSearchTerms,
    countAllPartsInAList,
    loadingCategories,
  } = useListsStore()
  const { push } = useHistory()
  useEffect(() => {
    const atleastOnePartSelected = getMultiSearchSelectedParts().length > 0

    if (atleastOnePartSelected) {
      setShowBottomDrawer(true)
    } else {
      setShowBottomDrawer(false)
    }
  }, [
    matchingFilteredSearchTerms,
    setShowBottomDrawer,
    getMultiSearchSelectedParts,
  ])

  const partOrParts = (selectedItems: number): string => {
    if (selectedItems > 1) {
      return 'parts'
    }
    return 'part'
  }

  useEffect(() => {
    const selectedParts = getMultiSearchSelectedParts()
    setSelectedItems(selectedParts.length)

    setShowSelectedItemsList(selectedParts as GroupPart[])
  }, [
    matchingFilteredSearchTerms,
    setSelectedItems,
    setShowSelectedItemsList,
    getMultiSearchSelectedParts,
  ])

  return (
    <Container>
      {matchingFilteredSearchTerms?.length > 0 ? (
        matchingFilteredSearchTerms?.map(
          (searchItem) =>
            searchItem.groups?.length > 0 && (
              <TableContainer key={searchItem.id}>
                <>
                  <Box display="flex" paddingLeft="25px">
                    <StyledChevron />
                    <StyledText normal bold>
                      {searchItem.category.name} | {searchItem.name} |{' '}
                      {searchItem.groups?.map((group, index) =>
                        searchItem.groups?.length < 2
                          ? `${group.name}`
                          : `${group.name}${
                              index < searchItem.groups?.length - 1 ? ' | ' : ''
                            }`
                      )}
                      &nbsp;
                    </StyledText>
                    <StyledText normal lightText>
                      &nbsp;
                      {`(${countAllPartsInAList(searchItem.id)} ${Translate(
                        partOrParts(countAllPartsInAList(searchItem.id))
                      )})`}
                    </StyledText>
                  </Box>
                  <tbody>
                    <Table>
                      <MultiSearchTableHeader id={searchItem.id} />
                      {searchItem.groups?.map((group, groupIndex) => (
                        <>
                          {group.items.map((part, index) => {
                            return (
                              <ListGroupPartRow
                                part={part}
                                key={part.id}
                                isSelected={part.isSelected}
                                noBorderBottom={
                                  index === group.items.length - 1 &&
                                  groupIndex === searchItem.groups?.length - 1
                                }
                              />
                            )
                          })}
                        </>
                      ))}
                    </Table>
                    <BottomDrawer />
                  </tbody>
                </>
              </TableContainer>
            )
        )
      ) : loadingCategories ? (
        <LinearProgress />
      ) : (
        <NoResults
          HeaderText="yourSearchReturnedNoResults"
          BodyText="tryAdjustingYourSearchOrLookupPartsInOurDatabase"
          action={() => push('/partsCatalog')}
          buttonText="partsCatalog"
        />
      )}

      <MoveGroupPartsDrawer />

      <MovePartsSelectionDrawer />

      <CartEditItemDrawer />
    </Container>
  )
})

const StyledChevron = styled(ChevronRight)`
  transform: rotate(-90deg);
  margin-right: 5px;
  color: ${defaultTheme.colors.almostBlack};
`

const Container = styled.div`
  width: 100%;
  background-color: ${defaultTheme.colors.componentsBackground};
`
const TableContainer = styled.div`
  width: 100%;
  background-color: ${defaultTheme.colors.componentsBackground};
  overflow-x: hidden;

  margin-top: 43px;
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`

interface StyledTextProps {
  lightText?: boolean
  normal?: boolean
  disableListTab?: boolean
  bold?: boolean
}

const StyledText = styled.span<StyledTextProps>`
  font-size: ${(p) => (p.normal ? '16px' : '22px')};
  font-family: Oswald;
  color: ${(p) => (p.lightText ? 'gray' : 'black')};
  font-weight: ${(p) => (!p.bold ? '500' : '700')};
  display: block;
  text-align: left;

  line-height: 24px;
  margin-bottom: 16px;
`
