import { Divider } from '@mui/material'
import { I18n, Translate } from 'src/i18n'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { defaultTheme } from 'src/theme'
import { Button, Thumbnail } from 'src/ui-components'
import styled from 'styled-components'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'

const defaultEditItemData = {
  mfr: '',
  part: '',
  qty: '',
  comment: '',
}

export const CartEditItemDrawer = observer((): ReactElement => {
  const [isValidated, setIsValidated] = useState<boolean>(false)
  const { showEditItemsDrawer, setShowEditItemsDrawer } = useUiStateStore()

  const [editItemsInputData, setEditItemsInputData] =
    useState(defaultEditItemData)

  useEffect(() => {
    if (showEditItemsDrawer) {
      setIsValidated(false)
      setEditItemsInputData(defaultEditItemData)
    }
  }, [showEditItemsDrawer])

  const handleCommentChange = (event: ChangeEvent) => {
    setEditItemsInputData((prev) => ({
      ...prev,
      comment: (event.target as HTMLInputElement).value,
    }))
  }

  const drawerBody = (
    <Container>
      <Divider />

      {!isValidated ? (
        <EditContainer>
          <TextFieldsContainer>
            <Column style={{ width: '20%' }}>
              <Container>
                <StyledI18n name="mfr" style={{ fontWeight: 'bold' }} />
                <TextInput
                  value={editItemsInputData.mfr}
                  onChange={(e) =>
                    setEditItemsInputData((prev) => ({
                      ...prev,
                      mfr: e.target.value,
                    }))
                  }
                  style={{ width: '100%', flex: 1 }}
                  maxLength={3}
                />
              </Container>
            </Column>

            <Column
              style={{
                width: '60%',
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              <Container>
                <StyledI18n name="partNum" style={{ fontWeight: 'bold' }} />
                <TextInput
                  value={editItemsInputData.part}
                  onChange={(e) =>
                    setEditItemsInputData((prev) => ({
                      ...prev,
                      part: e.target.value,
                    }))
                  }
                  style={{ width: '100%', flex: 1 }}
                  maxLength={20}
                />
              </Container>
            </Column>
            <Column style={{ width: '20%' }}>
              <Container>
                <StyledI18n name="qty" style={{ fontWeight: 'bold' }} />
                <TextInput
                  value={editItemsInputData.qty}
                  onChange={(e) =>
                    setEditItemsInputData((prev) => ({
                      ...prev,
                      qty: e.target.value,
                    }))
                  }
                  style={{ width: '100%', flex: 1 }}
                  maxLength={2}
                  type="number"
                  pattern="\d*"
                />
              </Container>
            </Column>
          </TextFieldsContainer>
          <StyledI18n
            name="commentOptional"
            style={{ marginTop: '15px', fontWeight: 'bold' }}
          />
          <TextArea
            value={editItemsInputData.comment}
            onChange={handleCommentChange}
          />

          <ButtonsContainer>
            <Button
              text="cancel"
              variant="secondary"
              onClick={() => {
                setShowEditItemsDrawer(false)
              }}
            />
            <Button
              text="validate"
              variant="primary"
              onClick={() => setIsValidated(true)}
            />
          </ButtonsContainer>
        </EditContainer>
      ) : (
        <ValidationContainer>
          <Row style={{ marginTop: '20px' }}>
            <CheckCircleIcon
              style={{
                color: defaultTheme.colors.green,
                marginLeft: '10px',
                marginRight: '10px',
              }}
            />
            <I18n
              name="itemValidated"
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            />
          </Row>

          <Row style={{ marginTop: '20px' }}>
            <Thumbnail urlOrBase64="" width={90} height={90} placeholder />
            <Container style={{ marginLeft: '15px' }}>
              <StyledText
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
                underline
              >
                WasherFluid
              </StyledText>
              <StyledText
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
                underline
              >
                (-20)
              </StyledText>
              <Row>
                <I18n
                  name="partNum"
                  style={{
                    fontSize: '16px',
                  }}
                />
                :<StyledText bold>{` ${editItemsInputData.part}`}</StyledText>
              </Row>
              <Row>
                <I18n
                  name="brand"
                  style={{
                    fontSize: '16px',
                  }}
                />
                : <StyledText bold>{` ${editItemsInputData.mfr}`}</StyledText>
              </Row>
            </Container>
          </Row>
          <ButtonsContainer>
            <Button
              text="backToReviewItems"
              variant="secondary"
              onClick={() => {
                setShowEditItemsDrawer(false)
              }}
            />
          </ButtonsContainer>
        </ValidationContainer>
      )}
    </Container>
  )

  return (
    <BaseDrawer
      open={showEditItemsDrawer}
      title={`${Translate('editUnknownItem')} `}
      onClose={() => setShowEditItemsDrawer(false)}
      width="600px"
      body={drawerBody}
    />
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Column = styled.td``

const TextInput = styled.input`
  color: black;
  padding: 10px 0;
  background-color: whitesmoke;
  border: 1px solid ${defaultTheme.colors.greyDark};
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
  text-align: left;
  padding: 10px;
  text-transform: uppercase;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const TextFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`
const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  border-radius: 5px;
`

interface StyledTextProps {
  underline?: boolean
  bold?: boolean
}

const StyledText = styled.span<StyledTextProps>`
  display: flex;
  text-decoration: ${(p) => (p.underline ? 'underline' : 'none')};
  font-weight: ${(p) => (p.bold ? 'bold' : 'none')};
`

const StyledI18n = styled(I18n)`
  display: flex;
`

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonsContainer = styled.div`
  width: 100%;
  justify-content: end;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`
