import { StorageKeys } from 'src/config/constants'

export const getCondensedViewState = (): boolean => {
  const isCondensedView =
    localStorage.getItem(StorageKeys.CONDENSEDVIEW_KEY) === 'true' || false
  try {
    return isCondensedView
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
    return false
  }
}
