import { observer } from 'mobx-react-lite'
import { ReactElement, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Config } from 'src/config/ConfigManager'
import {
  BlackBackground,
  LogoImage,
  WhiteBackground,
} from 'src/features/navigation/TopNavigation/components/CommonMenuItems'
import { BannerMessage } from 'src/features/promotion/BannerMessage'
import VehicleInfo from 'src/features/search/AllProductsSearch/components/VehicleInfo'
import { useVehicleWidgetStore } from 'src/features/search/VehicleSearch/store/VehicleWidgetStore'
import { VehicleWidget } from 'src/features/search/VehicleSearch/VehicleWidget'
import { AllProductsSearch, TopNavigationBar } from 'src/features'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import { BannerAdsHeader } from 'src/ui-components/util/BannerAdsHeader'
import styled from 'styled-components'
import { HeaderProps } from 'src/features/navigation/header/HeaderProps.ts'
import MobileHamburgerMenu from '../TopNavigation/components/MenuItemLinks/MobileHamburgerMenu.tsx'
import { useSearchStore } from 'src/store/search/SearchStore'
import Sticky from 'react-stickynode'
import { isMobile } from 'react-device-detect'
import { GfxSpecificConditionsModal } from 'src/features/gfx/gfxSpecificConditions/GfxSpecificConditionsModal'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { SpecificConditionsModal } from 'src/features/specificConditions/components/specificConditionsModal.tsx'
import Navbar from '../TopNavigation/components/Navbar.tsx'
import AccountMenu from '../TopNavigation/components/accountMenu/index.tsx'

const Header = observer((props: HeaderProps): ReactElement => {
  const { logoUrl } = props

  const blackSectionRef = useRef<HTMLDivElement>()
  const searchStore = useSearchStore()
  const vehicleWidgetStore = useVehicleWidgetStore()
  const { showFitmentConditions, showGfxConditions } = useUiStateStore()
  const location = useLocation()
  const isSticky =
    location.pathname === '/searchResults' || location.pathname === '/product/'

  const stickySectionRef = useRef<HTMLDivElement>(null)

  return (
    <>
      {Config.bannerEnabled && <BannerMessage />}
      {showFitmentConditions && <SpecificConditionsModal />}
      {showGfxConditions && <GfxSpecificConditionsModal />}

      <StickyInnerWrapper ref={stickySectionRef}>
        <TopNavigationBar>
          <BlackBackground ref={blackSectionRef}>
            <MobileHamburgerMenu />
            <NavLink to="/home">
              <LogoImage src={logoUrl} alt="My place 4 parts logo" />
            </NavLink>
            <NavbarWrapper>
              <Navbar />
            </NavbarWrapper>
          </BlackBackground>
          <WhiteBackground>
            <AccountMenu />
          </WhiteBackground>
        </TopNavigationBar>
        <Sticky innerZ={100} enabled={isMobile && isSticky}>
          <VehicleInfoWrapper>
            <VehicleInfo
              year={searchStore.currentVehicle?.year?.value}
              model={searchStore.currentVehicle?.model?.value}
              engine={searchStore.currentVehicle?.engine?.value}
              open={vehicleWidgetStore.isDrawerOpened}
              onClick={() => {
                vehicleWidgetStore.setIsDrawerOpened(
                  !vehicleWidgetStore.isDrawerOpened
                )
              }}
            />
          </VehicleInfoWrapper>
        </Sticky>
      </StickyInnerWrapper>
      <SearchContainer isSticky={isSticky}>
        <AllProductsSearchContainer>
          <AllProductsSearch />
        </AllProductsSearchContainer>
        <VehicleWidgetContainer>
          <VehicleWidget />
        </VehicleWidgetContainer>
      </SearchContainer>
      {import.meta.env.VITE_FEATURE_BANNER_ADS_HEADER === 'true' && (
        <BannerAdsHeader />
      )}
    </>
  )
})

interface isStickyProp {
  isSticky: boolean
}

const StickyInnerWrapper = styled.div``

const VehicleInfoWrapper = styled.div`
  position: relative;
  @media screen and (${breakpoints.tabletLarge}px <= width) {
    display: none;
  }
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    width: 100%;
    padding: 16px 16px 32px 16px;
    background: ${defaultTheme.colors.componentsBackground};
  }
`

const SearchContainer = styled.div<isStickyProp>`
  display: flex;
  flex-direction: column;
  position: ${(p) => (p.isSticky ? 'sticky' : 'initial')};
  top: 0;
  z-index: 1;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    position: initial;
  }
`

const AllProductsSearchContainer = styled.div`
  order: 1;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    order: 2;
  }
`

const VehicleWidgetContainer = styled.div`
  order: 2;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    order: 1;
  }
`

const NavbarWrapper = styled.div`
  width: 100%;
  padding: 0px 0px 0px 32px;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

export default Header
