import { BaseServiceProvider } from './BaseServiceProvider'
import { PartsBasketCreds, TokensFromAPI } from 'src/api/security/interfaces'

class TokenServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'token', '1.1', '')
  }

  getTokens = async (creds: PartsBasketCreds): Promise<TokensFromAPI> => {
    return this.post<TokensFromAPI>('token', creds, {
      headers: {
        'x-token-api-key': import.meta.env.VITE_TOKEN_API_KEY,
      },
    }).then((resp) => resp?.data)
  }
}

export default new TokenServiceProvider()
