import { IMenuItem } from './MenuItem'

export const featureFlagFilter = (items: IMenuItem[]): IMenuItem[] => {
  const featureFlags = {
    orders: import.meta.env.VITE_FEATURE_ORDER_DETAILS_ENABLED ?? false,
    quotes: import.meta.env.VITE_FEATURE_QUOTES_ENABLED ?? false,
    lists: import.meta.env.VITE_FEATURE_LISTS_ENABLED ?? false,
  }

  const filteredItems = items.filter(({ text }) => featureFlags[text] !== false)
  return filteredItems
}
