import { observer } from 'mobx-react-lite'
import { ReactElement, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import {
  PartsCatalogType,
  usePartsCatalog,
} from 'src/store/partsCatalog/PartsCatalog'
import { CatalogNodeType } from 'src/store/partsCatalog'
import { StoreInstances } from 'src/store/StoreInstancesContainer.ts'
import { MobileLaborLookupSteps } from './components/MobileLaborLookupSteps'
import { SelectionsInterface } from './interfaces'
import { SearchStoreContext } from 'src/store/search/SearchStore'
import VehicleRequiredModal from 'src/features/partsCatalog/Selections/VehicleRequiredModal.tsx'
import useSharedLaborSelectorMobileLogic from 'src/common-hooks/useSharedLaborSelectorMobileLogic.ts'
import { useLaborLookup } from 'src/store/labor/LaborStore'

export const LaborLookupSelectorMobile = observer(
  (
    props: SelectionsInterface & { setHideTitle: (arg: boolean) => void }
  ): ReactElement => {
    const { lookup: laborPartsCatalog } = useLaborLookup()
    const { categories, fetchCategories, fetchGroups, resetStore } =
      laborPartsCatalog
    const partsCatalog = usePartsCatalog()

    useEffect(() => {
      if (partsCatalog.categories && laborPartsCatalog) {
        partsCatalog.categories.forEach((item) => {
          if (item.selected) {
            laborPartsCatalog?.selectElement(item.id, CatalogNodeType.CATEGORY)
          } else {
            laborPartsCatalog?.unSelectElement(
              item.id,
              CatalogNodeType.CATEGORY
            )
          }
        })
        fetchGroups()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [laborPartsCatalog.categories, partsCatalog.selectedCategories])

    useEffect(() => {
      if (categories?.length === 0) {
        fetchCategories()
      }
    }, [categories, fetchCategories])

    useEffect(() => {
      resetStore()
    }, [resetStore])

    const { loading, setLoading, step, setStep, mode, setMode } =
      useSharedLaborSelectorMobileLogic(props)
    const history = useHistory()
    const {
      showFitmentConditionsDialog,
      hideFitmentConditionsDialog,
      setShowLaborLookupModal,
      setShowLaborResultModal,
    } = useUiStateStore()
    const searchStore = useContext(SearchStoreContext)
    const {
      fetchSpecificConditionQuestions,
      areAllQuestionsAnswered,
      processQuestions,
    } = useSpecificConditionStore()

    const setLaborLookup = setShowLaborLookupModal
    const setLaborResult = setShowLaborResultModal

    const goToSearchResults = (): void => {
      hideFitmentConditionsDialog()
      searchStore.searchByNavigation()
      history.push('/searchResults')
    }

    const goBack = (): void => {
      hideFitmentConditionsDialog()
    }

    const fetchAndShowResults = (): void => {
      setLaborLookup(false)
      setLaborResult(true)
    }

    const handleShowFitmentConditions = async () => {
      setLoading(true)
      try {
        const formattedVehicle = {
          ...StoreInstances.searchStore.currentVehicle,
          initialized: undefined,
        }

        const specificConditionQuestions =
          await fetchSpecificConditionQuestions(
            formattedVehicle,
            laborPartsCatalog.terminologies,
            PartsCatalogType.LABOR_SEARCH
          )
        if (
          specificConditionQuestions.length > 0 &&
          !areAllQuestionsAnswered()
        ) {
          setLaborLookup(false)
          showFitmentConditionsDialog({
            proceed: goToSearchResults,
            cancel: goBack,
          })
        } else {
          processQuestions()
          fetchAndShowResults()
        }
      } catch (_e) {
        // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
        console.error('Error fetching labor specific conditions')
      } finally {
        setLoading(false)
      }
    }

    return (
      <>
        <MobileLaborLookupSteps
          step={step}
          setStep={setStep}
          mode={mode}
          setMode={setMode}
          loading={loading}
          onClick={handleShowFitmentConditions}
          partsCatalog={laborPartsCatalog}
        />
        <VehicleRequiredModal isMobile />
      </>
    )
  }
)
