import { observer } from 'mobx-react-lite'
import { ReactElement, useState } from 'react'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { useLaborLookup } from 'src/store/labor/LaborStore'
import { PartsCatalogType } from 'src/store/partsCatalog/PartsCatalog'
import { defaultTheme } from 'src/theme'
import { Button } from 'src/ui-components'
import styled from 'styled-components'

interface DrawerFooterProps {
  setLaborLookup: (laborLookup: boolean) => void
  setLaborResult: (laborResult: boolean) => void
}

const DrawerFooter = observer(
  ({ setLaborLookup, setLaborResult }: DrawerFooterProps): ReactElement => {
    const [loading, setLoading] = useState(false)
    const { lookup: laborStore } = useLaborLookup()
    const {
      fetchSpecificConditionQuestions,
      areAllQuestionsAnswered,
      processQuestions,
    } = useSpecificConditionStore()
    const { showFitmentConditionsDialog, hideFitmentConditionsDialog } =
      useUiStateStore()

    const fetchAndShowResults = (): void => {
      setLaborLookup(false)
      setLaborResult(true)
    }

    const goToSearchResults = (): void => {
      hideFitmentConditionsDialog()
      fetchAndShowResults()
    }

    const goBack = (): void => {
      hideFitmentConditionsDialog()
      setLaborLookup(true)
    }

    const handleShowFitmentConditions = async (): Promise<void> => {
      setLoading(true)
      try {
        const formattedVehicle = {
          ...StoreInstances.searchStore.currentVehicle,
          initialized: undefined,
        }

        const specificConditionQuestions =
          await fetchSpecificConditionQuestions(
            formattedVehicle,
            laborStore.terminologies,
            PartsCatalogType.LABOR_SEARCH
          )
        if (
          specificConditionQuestions.length > 0 &&
          !areAllQuestionsAnswered()
        ) {
          setLaborLookup(false)
          showFitmentConditionsDialog({
            proceed: goToSearchResults,
            cancel: goBack,
          })
        } else {
          processQuestions()
          fetchAndShowResults()
        }
      } catch (_e) {
        // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
        console.error('Error fetching labor specific conditions')
      } finally {
        setLoading(false)
      }
    }

    return (
      <Footer>
        <DecorativeBox />
        <GeLaborDataWrapper>
          <Button
            onClick={handleShowFitmentConditions}
            text="getLaborData"
            style={{ margin: 0 }}
            size="small"
            disabled={laborStore.disabledButton}
            loading={loading}
          />
        </GeLaborDataWrapper>
      </Footer>
    )
  }
)
export default DrawerFooter

const Footer = styled.div`
  display: flex;
`

const DecorativeBox = styled.div`
  flex-grow: 1;
  background: ${defaultTheme.colors.disabledBackground};
`

const GeLaborDataWrapper = styled.div`
  margin-left: 1rem;
`
