import { ReactElement, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Translate } from 'src/i18n'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useUserStore } from 'src/store/user/UserStore'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'

import { observer } from 'mobx-react-lite'
import {
  CatalogIndexTracker,
  CatalogLookupType,
} from 'src/api/metrics/CatalogIndexTracker'
import SaytServiceProvider from 'src/services/SaytServiceProvider'
import { PartsCatalogSelections } from 'src/store/models/PartsCatalogModels'
import { PartsCatalogType } from 'src/store/partsCatalog/PartsCatalog'
import {
  NonCachedSearchType,
  SaytRecommendation,
  useSearchStore,
} from 'src/store/search/SearchStore'
import { useVehicleWidgetStore } from '../VehicleSearch/store/VehicleWidgetStore'
import { MultiPartInquiryDrawer } from './components/MultiPartInquiryDrawer'
import { MultiPartInquiryImportDrawer } from './components/MultiPartInquiryImportDrawer'
import { SearchField } from './components/SearchField'
import VehicleInfo from './components/VehicleInfo'
import {
  show as showNiceModal,
  hide as hideNiceModal,
} from '@ebay/nice-modal-react'
import AddVehicleForSearchModal from './components/AddVehicleForSearchModal'
import { SaytOption } from 'src/api/searchResults/interfaces'
import { ContactUsDrawer } from 'src/features/navigation/header/contactUs/ContactUsDrawer'
export const AllProductsSearch = observer((): ReactElement => {
  const searchStore = useSearchStore()
  const vehicleWidgetStore = useVehicleWidgetStore()
  const user = useUserStore()
  const history = useHistory()
  const location = useLocation()
  const uiStore = useUiStateStore()
  const currentPathname = location.pathname

  const { lastNonCachedSearch, lastInterchangeTextSearched } = searchStore

  const {
    fetchSpecificConditionQuestions,
    areAllQuestionsAnswered,
    processQuestions,
  } = useSpecificConditionStore()

  const [interchangeTextFieldValue, setInterchangeTextFieldValue] = useState('')

  const saveLocalStorageSearch = (search) => {
    if (search === '') {
      return
    }
    if (localStorage.getItem('searches')) {
      const searches = JSON.parse(localStorage.getItem('searches')).slice(0, 10)
      let addSearches
      if (searches[0]?.id === search) {
        addSearches = [...searches]
      } else {
        addSearches = [{ id: search }, ...searches]
      }
      localStorage.setItem('searches', JSON.stringify(addSearches))
    } else {
      localStorage.setItem('searches', JSON.stringify([{ id: search }]))
    }
  }

  const doSearchByText = async (
    text: string,
    catalogIndex: CatalogLookupType,
    saytSuggestions?: SaytOption[]
  ) => {
    searchStore.setShowPartsNotFitVehicleLabel(true)
    hideNiceModal(AddVehicleForSearchModal)
    CatalogIndexTracker.setIndex(catalogIndex)
    await searchStore.searchByText(text)
    searchStore.handleAddBreadCrumb({
      page: 'All Products',
      breadcumb: text,
      url: () => searchStore.searchByText(text),
    })
    storeSaytSuggestions(saytSuggestions)
  }

  const doVehicleSearch = async (
    text: string,
    catalogSelections: PartsCatalogSelections,
    saytSuggestions?: SaytOption[]
  ) => {
    searchStore.setShowPartsNotFitVehicleLabel(false)
    await handleShowFitmentConditions(catalogSelections, text)
    searchStore.handleAddBreadCrumb({
      page: 'All Products',
      breadcumb: text,
      url: () => handleShowFitmentConditions(catalogSelections, text),
    })
    storeSaytSuggestions(saytSuggestions)
  }

  const skipAndSearch = async (
    text: string,
    type: CatalogLookupType,
    vehicleSearchCallback: () => void
  ) => {
    uiStore.setSkipAddVehicleCallback(vehicleSearchCallback)
    await doSearchByText(text, type)
    history.push('/searchResults')
  }

  const showAddVehicleModal = (
    text: string,
    type: CatalogLookupType,
    partsCatalogSelections: PartsCatalogSelections,
    saytSuggestions?: SaytOption[]
  ) => {
    const vehicleSearch = async () => {
      await doVehicleSearch(text, partsCatalogSelections)
      storeSaytSuggestions(saytSuggestions)
    }
    showNiceModal(AddVehicleForSearchModal, {
      onAddVehicle: () => {
        vehicleWidgetStore.setAfterAddVehicle(vehicleSearch)
        vehicleWidgetStore.setIsDrawerOpened(true)
        hideNiceModal(AddVehicleForSearchModal)
      },
      onSkipVehicle: async () => {
        await skipAndSearch(text, type, vehicleSearch)
        vehicleWidgetStore.setIsDrawerOpened(false)
        storeSaytSuggestions(saytSuggestions)
        hideNiceModal(AddVehicleForSearchModal)
      },
    })
  }

  const onSelectedOption = (selected) => {
    const needVehicle = !searchStore.currentVehicle?.engine
    saveLocalStorageSearch(selected.label)
    if (selected.astSearch) {
      uiStore.setSkipAddVehicleCallback(undefined)
      doSearchByText(selected.label, CatalogLookupType.AST_MATCH)
      history.push('/searchResults')
    } else if (needVehicle) {
      showAddVehicleModal(
        selected.label,
        CatalogLookupType.AST_MATCH,
        selected.partCatalogMappings
      )
    } else {
      doVehicleSearch(selected.label, selected.partCatalogMappings)
      searchStore.lastTextSearched = selected.label
      history.push('/searchResults')
    }
  }

  const storeSaytSuggestions = (suggestions: SaytOption[]): void => {
    if (!suggestions?.length) return
    const saytSuggestions: SaytRecommendation[] = suggestions.map((s) => {
      return {
        text: s.label,
        onClick: () => {
          onSearch(s.label?.trim())
        },
      }
    })
    searchStore.saytRecommendations = saytSuggestions
  }

  const onSearch = async (text, saytSuggestions?: SaytOption[]) => {
    const needVehicle = !searchStore.currentVehicle?.engine
    saveLocalStorageSearch(text)
    const filteredSuggestions = saytSuggestions?.filter(
      (s) => s.label.toLowerCase() !== text.toLowerCase()
    )
    const synonymResults = await SaytServiceProvider.getSaytSynonymMatches(
      text,
      user.getLanguage()
    )
    if (synonymResults) {
      if (needVehicle) {
        showAddVehicleModal(
          text,
          CatalogLookupType.AST_NO_MATCH,
          synonymResults.partCatalogMappings,
          filteredSuggestions
        )
      } else {
        await doVehicleSearch(
          text,
          synonymResults.partCatalogMappings,
          filteredSuggestions
        )
      }
    } else {
      uiStore.setSkipAddVehicleCallback(undefined)
      await doSearchByText(
        text,
        CatalogLookupType.AST_NO_MATCH,
        filteredSuggestions
      )
      history.push('/searchResults')
    }
  }

  const handleShowFitmentConditions = async (
    catalogSelections: PartsCatalogSelections,
    searchText: string
  ) => {
    searchStore.setShowPartsNotFitVehicleLabel(false)

    const formattedVehicle = {
      ...searchStore.currentVehicle,
      initialized: undefined,
    }

    const terminologies = catalogSelections.reduce(
      (accumulator, currentValue) => {
        accumulator.push({
          groupId: +currentValue?.group?.id,
          id: +currentValue?.terminology?.id,
          value: '',
        })
        return accumulator
      },
      []
    )

    const specificConditionQuestions = await fetchSpecificConditionQuestions(
      formattedVehicle,
      terminologies,
      PartsCatalogType.PARTS_SEARCH
    )
    if (specificConditionQuestions.length > 0 && !areAllQuestionsAnswered()) {
      uiStore.showFitmentConditionsDialog({
        proceed: () => {
          searchStore.searchBySaytCategories(catalogSelections, 0)
          uiStore.hideFitmentConditionsDialog()
          history.push('/searchResults')
        },
        cancel: uiStore.hideFitmentConditionsDialog,
      })
    } else {
      processQuestions()
      searchStore.handleAddBreadCrumb({
        page: 'All Products',
        breadcumb: searchText,
        url: () => searchStore.searchBySaytCategories(catalogSelections, 0),
      })
      searchStore.searchBySaytCategories(catalogSelections, 0)
      history.push('/searchResults')
    }
  }

  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if (
      lastNonCachedSearch === NonCachedSearchType.searchByInterchange &&
      currentPathname === '/searchResults'
    ) {
      setInterchangeTextFieldValue(lastInterchangeTextSearched)
    }
  }, [lastInterchangeTextSearched, lastNonCachedSearch, currentPathname])

  useEffect(() => {
    if (currentPathname !== '/home' && currentPathname !== '/partsCatalog') {
      vehicleWidgetStore.setIsDrawerOpened(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPathname])

  return (
    <Wrapper show={offset} id="allProductsSearch">
      <FieldsWrapper>
        <VehicleInfoWrapper>
          <VehicleInfo
            year={searchStore.currentVehicle?.year?.value}
            model={searchStore.currentVehicle?.model?.value}
            engine={searchStore.currentVehicle?.engine?.value}
            open={vehicleWidgetStore.isDrawerOpened}
            onClick={() =>
              vehicleWidgetStore.setIsDrawerOpened(
                !vehicleWidgetStore.isDrawerOpened
              )
            }
          />
        </VehicleInfoWrapper>

        <SearchBoxWrapper>
          <SearchField
            label={Translate('allProducts')}
            placeholder={Translate('searchPartBrandName')}
            onSearch={(text, saytSuggestions) => {
              if (text.length > 0) {
                onSearch(text.trim(), saytSuggestions)
              }
            }}
            onSelectOption={onSelectedOption}
            type="sayt"
            multiPartInquiry={
              import.meta.env.VITE_FEATURE_MULTI_PART_INQUIRY_ENABLED === 'true'
            }
          />
        </SearchBoxWrapper>

        <OrDivider>{Translate('or')}</OrDivider>

        <OrDividerContainer>
          <OrDividerLine />
          <OrDividerMobile>{Translate('or')}</OrDividerMobile>
          <OrDividerLine />
        </OrDividerContainer>

        <SearchBoxWrapper>
          <SearchField
            label={Translate('interchange')}
            placeholder={Translate('separateMultiplePartNumbers')}
            onSearch={(text) => {
              if (text.length > 0) {
                const trimmedText = text.trim()
                uiStore.setSkipAddVehicleCallback(undefined)
                searchStore.searchByInterchange(trimmedText)
                searchStore.handleAddBreadCrumb({
                  page: 'interchange',
                  breadcumb: trimmedText,
                  url: () => searchStore.searchByInterchange(trimmedText),
                })
                history.push('/searchResults')
              }
            }}
            sayt={false}
            type="interchange"
            textFieldValue={interchangeTextFieldValue}
          />
        </SearchBoxWrapper>
      </FieldsWrapper>
      <ContactUsDrawer
        open={uiStore.showContactDrawer}
        onCloseDrawer={() => uiStore.setShowContactDrawer(false)}
      />
      <MultiPartInquiryDrawer />
      <MultiPartInquiryImportDrawer />
    </Wrapper>
  )
})

interface WrapperProps {
  show: number
}
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  min-height: 100px;
  height: fit-content;
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media print {
    display: none;
  }
`

const VehicleInfoWrapper = styled.div`
  margin: 0px 32px 0px 0px;
  background-color: ${defaultTheme.colors.rowFeaturedBackground};
  height: 56px;
  width: 100%;
  overflow: hidden;

  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 728px;
  }

  @media screen and (${breakpoints.laptopLarge}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 527px;
    max-width: 728px;
    margin: 0px 32px 0px 0px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopLarge}px) {
    min-width: 385px;
    max-width: 527px;
    margin: 0px 16px 0px 0px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 289px;
    max-width: 385px;
    margin: 0px 20px 0px 0px;
  }

  @media screen and (width <= ${breakpoints.laptop}px) {
    min-width: 288px;
    max-width: 289px;
    margin: 0px 17px 0px 0px;
  }

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const FieldsWrapper = styled.div`
  padding: 22px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: inherit;
  width: 100%;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    padding: 16px;
    width: 100%;
    flex-wrap: wrap;
    background-color: ${defaultTheme.colors.white};
  }
`
const OrDivider = styled.span`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  margin-left: 10px;
  margin-right: 10px;

  @media (width > ${breakpoints.laptopXLarge}px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (width <= ${breakpoints.laptopXLarge}px) {
    margin-left: 16px;
    margin-right: 16px;
  }

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const OrDividerMobile = styled(OrDivider)`
  display: none;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    font-size: 16px;
    display: block;
  }
`

const OrDividerContainer = styled.div`
  display: none;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 12px 0;
  }
`
const OrDividerLine = styled.div`
  width: 50%;
  height: 1px;
  display: none;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: block;
  }
  background: #e5e4e1;
`

const SearchBoxWrapper = styled.div`
  width: 100%;
  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 519px;
  }

  @media screen and (${breakpoints.laptopLarge}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 395px;
    max-width: 519px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopLarge}px) {
    min-width: 395px;
    max-width: 395px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 310px;
    max-width: 395px;
  }

  @media screen and (${breakpoints.tabletLarge}px <= width <= ${breakpoints.laptop}px) {
    min-width: 303px;
    max-width: 310px;
  }

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    width: 100%;
  }
`
