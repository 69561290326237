import { ReactElement, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSearchStore } from 'src/store/search/SearchStore'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { VehicleSelector } from 'src/features'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
  VehicleWidgetStore,
} from '../store/VehicleWidgetStore'
import { VehicleQuickFind } from 'src/features/search/VehicleSearch/components/VehicleQuickFind'
import { VehicleSelectorStep } from 'src/ui-components/util/Interfaces'
import { breakpoints } from 'src/theme/breakpoints'

export const YMMESelector = observer((): ReactElement => {
  const history = useHistory()
  const vehicleWidgetStore = useVehicleWidgetStore()
  const { currentVehicle } = useSearchStore()
  const [selectorStartStep, setSelectorStartStep] =
    useState<VehicleSelectorStep>(
      VehicleWidgetStore.getActiveVehicleStep(currentVehicle)
    )

  const handleVehicleSelected = useCallback(() => {
    vehicleWidgetStore.setIsDrawerOpened(false)
    vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.view)
    const afterAddVehicleCallback = vehicleWidgetStore.afterAddVehicle()
    if (!afterAddVehicleCallback) {
      history.push('/partsCatalog')
    } else {
      afterAddVehicleCallback()
      vehicleWidgetStore.setAfterAddVehicle(undefined)
    }
  }, [history, vehicleWidgetStore])

  useEffect(() => {
    setSelectorStartStep(
      VehicleWidgetStore.getActiveVehicleStep(currentVehicle)
    )
  }, [currentVehicle])

  return (
    <Wrapper>
      <VehicleQuickFindWrapper>
        <VehicleQuickFind
          onVehicleSelected={handleVehicleSelected}
          vehicleLookup={true}
        />
      </VehicleQuickFindWrapper>

      <VehicleSelectorWrapper>
        <VehicleSelector
          onVehicleSelected={handleVehicleSelected}
          startStep={selectorStartStep}
        />
      </VehicleSelectorWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
  gap: 20px;
  width: 100%;
  font-family: Roboto, serif;
`

const VehicleQuickFindWrapper = styled.div`
  max-width: 378px;
`

const VehicleSelectorWrapper = styled.div`
  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 1614px;
  }

  @media screen and (${breakpoints.laptopLarge}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 1255px;
    max-width: 1614px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopLarge}px) {
    min-width: 1231px;
    max-width: 1255px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    width: 100%;
  }
`
