import { ProductModel } from 'src/store/models/ProductModel'
import { LocalInventoryMessage } from '../types/localInventory'

import BasePartnerCommunicationManager from './BasePartnerCommunicationManager'
import COSTARCommunicationManager from './COSTARCommunicationManager'

export interface IBasePartnerCommunicationManager {
  cartCallBack: (data: string) => void
  cancelCallBack: () => void
  getLocalInventory(
    results: LocalInventoryMessage,
    originalParts: ProductModel[],
    callBack: (data: ProductModel[]) => void
  ): void
}

class PartnerCommunicationManager {
  private static _instance: IBasePartnerCommunicationManager

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    //
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static initializeCommunicationManager(
    partnerId: string,
    isAesServer: boolean = false
  ): void {
    if (this._instance) {
      throw new Error('Partnercommunicationmanager already initialized')
    }
    switch (partnerId) {
      case 'COSTAR': {
        // Older version of COSTAR used aesServer. So using the BasePartnerCommunicationManager in case of aesServer.
        if (isAesServer) {
          this._instance = new BasePartnerCommunicationManager()
        } else {
          this._instance = new COSTARCommunicationManager()
        }
        break
      }
      default: {
        this._instance = new BasePartnerCommunicationManager()
      }
    }
  }

  public static getPartnercommunicationmanager(): IBasePartnerCommunicationManager {
    return this._instance
  }
}

export default PartnerCommunicationManager
