import { Auth } from '@aws-amplify/auth'
import { getAwsConfig } from '../../aws-exports'
import { UserAttributes } from '../../store/user/interfaces'
import { AuthI, IUserInfo } from './interfaces'
import { getUserAttributes, updateUserAttributes } from '../embedded/GraphQlAPI'

export class AwsAuth implements AuthI {
  private userInfo: IUserInfo
  constructor() {
    const awsConfig = getAwsConfig()
    Auth.configure(awsConfig)
  }

  public getCurrentToken = async (): Promise<string> => {
    const currentSession = await Auth.currentSession()
    return currentSession.getIdToken().getJwtToken()
  }

  getUserAttributes = async (): Promise<UserAttributes> => {
    const [token, userInfo] = await Promise.all([
      this.getCurrentToken(),
      Auth.currentUserInfo(),
    ])

    this.userInfo = userInfo

    return getUserAttributes(userInfo.username, token)
  }

  /**
   * @deprecated
   * We need to call the saveOrUpdateAttribute endpoint.
   * @param UserAttributes
   */
  updateUserAttributes = async (attributes: UserAttributes): Promise<void> => {
    const [token, userInfo] = await Promise.all([
      this.getCurrentToken(),
      Auth.currentUserInfo(),
    ])
    return updateUserAttributes(attributes, userInfo.username, token)
  }

  public signIn(username: string, password: string): Promise<unknown> {
    return Auth.signIn(username, password)
  }

  public signOut(): Promise<unknown> {
    return Auth.signOut()
  }

  public getUserId(): string {
    return this.userInfo.username
  }
}
