import { CatalogAPI } from './ApiInterface'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import CatalogServiceProvider from 'src/services/CatalogServiceProvider'
import {
  IdGroupsResponse,
  IdsEnginesResponse,
  IPartItemResponse,
} from 'src/features/partsCatalog/Selections/interfaces'
import { CatalogTreeParser } from '../CatalogTreeParser'
import { CatalogNode } from '../CatalogNode'
import { Vehicle } from 'src/store/models/Vehicles'

export class LaborCatalogAPI implements CatalogAPI {
  SESSION_STORAGE_KEY

  constructor() {
    this.SESSION_STORAGE_KEY = 'selected-labor-catalog-tree'
  }

  fetchCategories = async (
    vehicle?: Vehicle
  ): Promise<Array<IdValueGeneric<string, string>>> => {
    const resp = await CatalogServiceProvider.fetchLaborCategories(vehicle)
    return resp
  }

  fetchGroups = async (
    categories: Array<IdValueGeneric<string, string>>,
    vehicle?: Vehicle
  ): Promise<IdGroupsResponse> => {
    const resp = await CatalogServiceProvider.fetchLaborGroups(
      categories,
      vehicle
    )
    return resp
  }

  fetchPartTypes = async (
    groups: Array<IdValueGeneric<string, string>>,
    vehicle: Vehicle
  ): Promise<IPartItemResponse | IdsEnginesResponse> => {
    const resp = await CatalogServiceProvider.fetchLaboeTerminologies(
      groups,
      vehicle
    )
    return resp
  }

  retrieveSelectionsFromSession = (parser: CatalogTreeParser): CatalogNode => {
    const savedJson = sessionStorage.getItem(this.SESSION_STORAGE_KEY)
    if (savedJson?.length > 3) return parser.parse(savedJson)
    return undefined
  }

  storeSelectionsOnSession = (selections: string): void => {
    sessionStorage.setItem(this.SESSION_STORAGE_KEY, selections)
  }

  isGfxSupported = (): boolean => {
    return false
  }
}
