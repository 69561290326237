import { CircularProgress, Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { ReactElement, useEffect, useState } from 'react'
import { I18n, Translate } from 'src/i18n'
import { defaultTheme } from 'src/theme'
import { Button, SimpleModal } from 'src/ui-components'
import styled from 'styled-components'

import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Close } from '@mui/icons-material'
import DoneIcon from '@mui/icons-material/Done'
import WarningIcon from '@mui/icons-material/Warning'
import { ImportStatus, ListsStore } from 'src/store/lists/ListsStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { create as createNiceModal, useModal } from '@ebay/nice-modal-react'

interface ImportListPartsProps {
  listId: string
  store: ListsStore
}

const defaultImportListState = {
  selectedFile: false,
  disableImportButton: true,
  fileToUpload: null,
  fileUploadedStatus: false,
}

export const ImportListPartsModal = createNiceModal<ImportListPartsProps>(
  observer((props: ImportListPartsProps): ReactElement => {
    const { listId, store } = props
    const modal = useModal()

    const [importListFileState, setImportListFileState] = useState(
      defaultImportListState
    )

    const [isImportFinished, setIsImportFinished] = useState(false)

    const {
      importPartsList,
      listImportRespData,
      importListPartsLoading,
      listImportDetails,
    } = store

    const { displayErrorNotification } = useUiStateStore()

    useEffect(() => {
      setImportListFileState(defaultImportListState)
    }, [modal.visible])

    useEffect(() => {
      if (!modal.visible) {
        setIsImportFinished(false)
      }
    }, [setIsImportFinished, modal.visible])

    useEffect(() => {
      if (listImportRespData && listImportRespData?.queueId !== '') {
        setImportListFileState((prevState) => ({
          ...prevState,
          fileUploadedStatus: true,
        }))
      }
      if (listImportRespData && listImportRespData?.queueId === '') {
        displayErrorNotification('pleaseUploadCsvFile')
        setImportListFileState((prevState) => ({
          ...prevState,
          disableImportButton: false,
        }))
      }
    }, [listImportRespData, displayErrorNotification])

    const handleFileSelected = (
      e: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const filesArray = Array.from(e.target.files)
      const uploadFile = filesArray[0]

      if (uploadFile.size > 0) {
        setImportListFileState((prevState) => ({
          ...prevState,
          selectedFile: true,
          fileToUpload: uploadFile,
          disableImportButton: false,
        }))
      }
    }

    const handleFileImport = async () => {
      setImportListFileState((prevState) => ({
        ...prevState,
        disableImportButton: true,
        fileUploading: true,
      }))
      const status = await importPartsList(
        listId,
        importListFileState.fileToUpload
      )
      if (status != ImportStatus.FAILED) setIsImportFinished(true)
    }

    const fileSizeConversion = (bytes: number, decimals = 2) => {
      if (bytes === 0) return '0 B'

      const kiloByte = 1000
      const decimal = decimals < 0 ? 0 : decimals
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i: number = Math.floor(Math.log(bytes) / Math.log(kiloByte))

      return `${parseFloat((bytes / kiloByte ** i).toFixed(decimal))} ${
        sizes[i]
      }`
    }

    return (
      <SimpleModal
        show={modal.visible}
        title={
          !isImportFinished
            ? Translate('importList')
            : Translate('importComplete')
        }
        primaryButtonVariant="primary"
        size="lg"
        onSecondaryAction={() => {
          modal.remove()
        }}
      >
        <MainContainer>
          {!isImportFinished && (
            <ImportFileContainer>
              <Divider />
              <I18n name="importListDescription" style={{ fontSize: '16px' }} />
              <a
                href="/csv/LIST_TEMPLATE.csv"
                style={{ color: defaultTheme.colors.link }}
              >
                <I18n
                  name="useTemplateCsv"
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                  }}
                />
              </a>

              {!importListFileState.selectedFile ? (
                <BrowseFileContainer>
                  <FileUploadIcon style={{ color: 'lightgrey', scale: '2' }} />
                  <Button
                    text="browseFile"
                    variant="primary"
                    size="medium"
                    style={{ marginTop: '25px' }}
                  />
                  <I18n
                    name="dragAndDrop"
                    style={{ fontSize: '16px', marginTop: '15px' }}
                  />

                  <BrowseFileFieldWrapper>
                    <BrowseFileField
                      type="file"
                      onChange={handleFileSelected}
                      title=""
                      value=""
                      accept=".csv"
                    />
                  </BrowseFileFieldWrapper>
                </BrowseFileContainer>
              ) : (
                <SelectedFileContainer>
                  <FileSizeContainer>
                    <StyledText>{`${
                      importListFileState.fileToUpload.name
                    } (${fileSizeConversion(
                      importListFileState.fileToUpload.size
                    )})`}</StyledText>
                    {!importListPartsLoading ? (
                      <Close
                        style={{ color: '#0F172A', cursor: 'pointer' }}
                        onClick={() => {
                          setImportListFileState(defaultImportListState)
                        }}
                      />
                    ) : null}
                  </FileSizeContainer>
                  {importListPartsLoading ? (
                    <CircularProgress
                      style={{
                        marginLeft: '15px',
                        marginRight: '15px',
                        color: defaultTheme.colors.primary,
                      }}
                    />
                  ) : null}
                </SelectedFileContainer>
              )}

              <ButtonsContainer>
                <Button
                  text="cancel"
                  variant="secondary"
                  size="medium"
                  onClick={() => {
                    modal.remove()
                  }}
                />
                <Button
                  text="import"
                  variant="primary"
                  size="medium"
                  disabled={importListFileState.disableImportButton}
                  onClick={handleFileImport}
                />
              </ButtonsContainer>
            </ImportFileContainer>
          )}

          {importListFileState.fileUploadedStatus && isImportFinished && (
            <FileUploadedContainer>
              <DoneIcon
                style={{ color: defaultTheme.colors.green, scale: '2' }}
              />
              <I18n
                name={Translate('listImportedSuccessfully', [
                  listImportDetails?.successParts,
                ])}
                style={{
                  fontSize: '16px',
                  marginTop: '15px',
                  fontWeight: 'bold',
                }}
              />

              {listImportDetails?.failureParts > 0 && (
                <ErrorContainer>
                  <WarningIcon
                    style={{
                      color: defaultTheme.colors.red,
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                  />
                  <I18n
                    name={Translate('listImportedError', [
                      listImportDetails.failureParts,
                    ])}
                    style={{ fontSize: '16px' }}
                  />
                </ErrorContainer>
              )}

              <ButtonsContainer>
                <Button
                  text="finish"
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    modal.remove()
                  }}
                />
              </ButtonsContainer>
            </FileUploadedContainer>
          )}
        </MainContainer>
      </SimpleModal>
    )
  })
)

const MainContainer = styled.div``

const BrowseFileFieldWrapper = styled.div`
  height: 175px;
  width: 100%;
  cursor: pointer;
  position: absolute;
  margin: -20px;
`

const ImportFileContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const BrowseFileContainer = styled.div`
  border-style: dashed;
  border-color: lightgrey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  margin-top: 15px;
`

export const BrowseFileField = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const SelectedFileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
`

const FileSizeContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  width: 100%;
  background-color: whitesmoke;
`

const StyledText = styled.span`
  font-size: 14px;
  font-family: Roboto, serif;
  color: black;
  font-weight: bold;
  display: block;
  text-align: left;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 15px;
  align-items: center;
  width: 100%;
`

const FileUploadedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 5px;
  border: 1px solid silver;
  padding: 10px;
  margin-top: 15px;
`
