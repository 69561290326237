import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIsMounted } from 'src/hooks'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { breakpoints, specialBreakpoints } from 'src/theme/breakpoints'
import { Button } from 'src/ui-components'
import styled from 'styled-components'
import { EditControls } from './EditControls'
import { VehicleDropdown } from './VehicleDropdown'
import UserDataServiceProvider from 'src/services/UserDataServiceProvider'
import VehicleServiceProvider from 'src/services/VehicleServiceProvider'
import { usePartsCatalog } from 'src/store/partsCatalog/PartsCatalog'
import {
  AUTO_LIGHT_MEDIUM_TRUCK_ID,
  useSearchStore,
} from 'src/store/search/SearchStore'
import { VehicleDropdownOnChangeProps } from '../interfaces'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
  VehicleWidgetErrorState,
} from '../store/VehicleWidgetStore'
import { useVehicleMakesStore } from '../store/VehicleMakesStore'
import { VehicleSelectorStep } from 'src/ui-components/util/Interfaces'

const stepOrder = [
  VehicleSelectorStep.START,
  VehicleSelectorStep.TYPE,
  VehicleSelectorStep.YEAR,
  VehicleSelectorStep.MAKE,
  VehicleSelectorStep.MODEL,
  VehicleSelectorStep.ENGINE,
  VehicleSelectorStep.END,
]

interface VehicleSelectorInterface {
  onVehicleSelected: () => void
  startStep?: VehicleSelectorStep
}

export const VehicleSelector = observer(
  ({
    onVehicleSelected,
    startStep: startStepProps,
  }: VehicleSelectorInterface): ReactElement => {
    const startStep = startStepProps || VehicleSelectorStep.TYPE
    const history = useHistory()
    const { clearStore, resetStore } = usePartsCatalog()
    const isMounted = useIsMounted()
    const searchStore = useSearchStore()
    const vehicleWidgetStore = useVehicleWidgetStore()
    const makesStore = useVehicleMakesStore()
    const typeId = searchStore.currentFieldsSelections.type?.id
    const isMakesFilterEnabled = makesStore.isMakesFilterEnabled()
    const filterMakes = useMemo(
      () => isMakesFilterEnabled && AUTO_LIGHT_MEDIUM_TRUCK_ID === typeId,
      [isMakesFilterEnabled, typeId]
    )

    const editing =
      vehicleWidgetStore.displayState === VehicleWidgetDisplayState.edit
    const [isYearAutoOpened, setIsYearAutoOpened] = useState<boolean>(false)

    const {
      currentFieldsSelections: currentSelections,
      setCurrentFieldsSelections: setCurrentSelections,
      currentVehicle,
    } = searchStore

    const [userClickedOnEngine, setUserClickedOnEngine] = useState(false)
    const [currentStep, setCurrentStep] = useState(startStep)
    const [selectorOptions, setSelectorOptions] = useState({
      type: [],
      year: [],
      make: [],
      model: [],
      engine: [],
    })
    const { clearAnswers: clearSpecificConditionAnswers } =
      useSpecificConditionStore()

    const getCurrentStep = useCallback((): VehicleSelectorStep => {
      if (currentSelections.engine?.value) return VehicleSelectorStep.END
      if (currentSelections.model?.value) return VehicleSelectorStep.ENGINE
      if (currentSelections.make?.value) return VehicleSelectorStep.MODEL
      if (currentSelections.year?.value) return VehicleSelectorStep.MAKE
      if (currentSelections.type?.value) return VehicleSelectorStep.YEAR

      if (startStep) {
        return startStep
      }
      return VehicleSelectorStep.TYPE
    }, [currentSelections, startStep])

    const calculateCurrentStep = useCallback(() => {
      setCurrentStep(getCurrentStep())
    }, [getCurrentStep])

    const cancelCurrentStep = useCallback(() => {
      setCurrentStep(undefined)
    }, [])

    const canSelect = (step) => {
      if (step === VehicleSelectorStep.TYPE) {
        return true
      }
      const currentStepIdx = stepOrder.indexOf(currentStep)
      const compareIdx = stepOrder.indexOf(step)
      return currentStepIdx >= compareIdx
    }

    const setVehicleOption = useCallback(
      (id: string, option: VehicleDropdownOnChangeProps) => {
        const updatedSelections = { ...currentSelections }
        updatedSelections[id] = option.selection
        if (id === VehicleSelectorStep.TYPE) {
          updatedSelections.year = undefined
        }
        if (
          id === VehicleSelectorStep.TYPE ||
          id === VehicleSelectorStep.YEAR
        ) {
          updatedSelections.make = undefined
        }
        if (
          id === VehicleSelectorStep.TYPE ||
          id === VehicleSelectorStep.YEAR ||
          id === VehicleSelectorStep.MAKE
        ) {
          updatedSelections.model = undefined
        }
        if (
          id === VehicleSelectorStep.TYPE ||
          id === VehicleSelectorStep.YEAR ||
          id === VehicleSelectorStep.MAKE ||
          id === VehicleSelectorStep.MODEL
        ) {
          updatedSelections.engine = undefined
        }
        if (id === VehicleSelectorStep.MODEL) {
          updatedSelections.modelType = option.selectionType
        }
        setCurrentSelections(updatedSelections)
      },
      [currentSelections, setCurrentSelections]
    )

    const handleSelect = useCallback(
      (step, selection) => {
        setVehicleOption(step, selection)
        switch (step) {
          case VehicleSelectorStep.TYPE:
            setTimeout(() => {
              setCurrentStep(VehicleSelectorStep.YEAR)
            })
            break
          case VehicleSelectorStep.YEAR:
            setSelectorOptions({
              ...selectorOptions,
              make: [],
              model: [],
              engine: [],
            })
            setTimeout(() => {
              setCurrentStep(VehicleSelectorStep.MAKE)
            })
            break
          case VehicleSelectorStep.MAKE:
            setSelectorOptions({
              ...selectorOptions,
              model: [],
              engine: [],
            })
            setTimeout(() => {
              setCurrentStep(VehicleSelectorStep.MODEL)
            })
            break
          case VehicleSelectorStep.MODEL:
            setSelectorOptions({
              ...selectorOptions,
              engine: [],
            })
            setTimeout(() => {
              setCurrentStep(VehicleSelectorStep.ENGINE)
            })
            break
          case VehicleSelectorStep.ENGINE:
            searchStore.setShowVehicleTransition(true)
            setCurrentStep(VehicleSelectorStep.END)
            setUserClickedOnEngine(true)
            break
          default:
            break
        }
      },
      [searchStore, selectorOptions, setVehicleOption]
    )

    const handleGo = useCallback(async () => {
      await searchStore.updateCurrentVehicle({
        ...currentSelections,
        vin: null,
        plate: null,
      })
      resetStore()
      clearSpecificConditionAnswers()
      vehicleWidgetStore.setIsDrawerOpened(false)
      vehicleWidgetStore.setErrorState(VehicleWidgetErrorState.none)
      vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.view)
      onVehicleSelected()
    }, [
      clearSpecificConditionAnswers,
      searchStore,
      currentSelections,
      onVehicleSelected,
      resetStore,
      vehicleWidgetStore,
    ])

    useEffect(() => {
      if (
        currentSelections.engine?.id &&
        currentStep === VehicleSelectorStep.END &&
        userClickedOnEngine
      ) {
        handleGo()
        setUserClickedOnEngine(false)
        if (editing) {
          clearStore()
          history.push('/partsCatalog')
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelections, currentStep, editing, handleGo, userClickedOnEngine])

    const handleClearSelections = useCallback(() => {
      setCurrentSelections({
        ...currentSelections,
        year: undefined,
        make: undefined,
        model: undefined,
        engine: undefined,
      })
    }, [currentSelections, setCurrentSelections])

    useEffect(() => {
      makesStore.setIsLoadingMakes(true)
      VehicleServiceProvider.getSelectorOptions(
        currentStep,
        currentSelections || {},
        filterMakes
      )
        .then((options) => {
          if (!isMounted()) {
            return
          }
          setSelectorOptions((prevState) => ({
            ...prevState,
            [currentStep]: options,
          }))
        })
        .finally(() => makesStore.setIsLoadingMakes(false))
    }, [currentStep, currentSelections, isMounted, filterMakes, makesStore])

    useEffect(calculateCurrentStep, [calculateCurrentStep])

    const searchDisabled = currentStep !== VehicleSelectorStep.END

    useEffect(() => {
      if (Object.keys(currentVehicle).length !== 0) {
        setCurrentSelections({
          type: currentVehicle.type,
          year: currentVehicle.year,
          make: currentVehicle.make,
          model: currentVehicle.model,
          engine: currentVehicle.engine,
          modelType: currentVehicle.modelType,
        })
      }
    }, [currentVehicle, setCurrentSelections])

    useEffect(() => {
      if (currentSelections?.type === undefined) {
        UserDataServiceProvider.getTypeOptions().then((vehicleCatalogs) => {
          const defaultSelection: VehicleDropdownOnChangeProps = {
            selection: vehicleCatalogs[0]?.options[0],
            selectionType: { id: 0, value: '' },
          }
          handleSelect(VehicleSelectorStep.TYPE, defaultSelection)
          setIsYearAutoOpened(true)
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const { getSelectedMakes } = VehicleServiceProvider
      const setupToggle = async (): Promise<void> => {
        const enabled = await VehicleServiceProvider.isMakesFilterEnabled()
        makesStore.setMakesFilterEnabled(enabled)
        const selectedMakes = await getSelectedMakes()
        makesStore.setSelectedMakes(selectedMakes)
        makesStore.setShowMakesToggle(selectedMakes?.length > 0)
      }
      setupToggle()
    }, [makesStore])

    return (
      <>
        <FirstRow>
          <AddVehicleControls>
            <VehicleDropdowns>
              <VehicleType>
                <VehicleDropdown
                  label="vehicleType"
                  selection={currentSelections.type}
                  active={currentStep === VehicleSelectorStep.TYPE}
                  disabled={false}
                  onClick={() => setCurrentStep(VehicleSelectorStep.TYPE)}
                  onChange={(selection) =>
                    handleSelect(VehicleSelectorStep.TYPE, selection)
                  }
                  onCancel={cancelCurrentStep}
                  options={selectorOptions.type}
                  enableSearch
                />
              </VehicleType>
              <Year>
                <VehicleDropdown
                  label="year"
                  selection={currentSelections.year}
                  active={
                    currentStep === VehicleSelectorStep.YEAR &&
                    !isYearAutoOpened
                  }
                  disabled={!canSelect(VehicleSelectorStep.YEAR)}
                  onClick={() => {
                    setIsYearAutoOpened(false)
                    setCurrentStep(VehicleSelectorStep.YEAR)
                  }}
                  onChange={(selection) => {
                    setIsYearAutoOpened(false)
                    handleSelect(VehicleSelectorStep.YEAR, selection)
                  }}
                  onCancel={cancelCurrentStep}
                  options={selectorOptions.year}
                  enableSearch
                  disableSearch={currentSelections.type === undefined}
                />
              </Year>
              <Make>
                <VehicleDropdown
                  label="make"
                  selection={currentSelections.make}
                  active={
                    currentStep === VehicleSelectorStep.MAKE &&
                    !isYearAutoOpened
                  }
                  disabled={!canSelect(VehicleSelectorStep.MAKE)}
                  onClick={() => {
                    setIsYearAutoOpened(false)
                    setCurrentStep(VehicleSelectorStep.MAKE)
                  }}
                  onChange={(selection) => {
                    setIsYearAutoOpened(false)
                    handleSelect(VehicleSelectorStep.MAKE, selection)
                  }}
                  onCancel={cancelCurrentStep}
                  options={selectorOptions.make}
                  enableSearch
                  disableSearch={currentSelections.year === undefined}
                />
              </Make>
              <Model>
                <VehicleDropdown
                  label="model"
                  selection={currentSelections.model}
                  active={
                    currentStep === VehicleSelectorStep.MODEL &&
                    !isYearAutoOpened
                  }
                  disabled={!canSelect(VehicleSelectorStep.MODEL)}
                  onClick={() => {
                    setIsYearAutoOpened(false)
                    setCurrentStep(VehicleSelectorStep.MODEL)
                  }}
                  onChange={(selection) => {
                    setIsYearAutoOpened(false)
                    handleSelect(VehicleSelectorStep.MODEL, selection)
                  }}
                  onCancel={cancelCurrentStep}
                  options={selectorOptions.model}
                  enableSearch
                  disableSearch={currentSelections.make === undefined}
                />
              </Model>
              <Engine>
                <VehicleDropdown
                  label="engine"
                  selection={currentSelections.engine}
                  active={
                    currentStep === VehicleSelectorStep.ENGINE &&
                    !isYearAutoOpened
                  }
                  disabled={!canSelect(VehicleSelectorStep.ENGINE)}
                  onClick={() => {
                    setIsYearAutoOpened(false)
                    setCurrentStep(VehicleSelectorStep.ENGINE)
                  }}
                  onChange={(selection) => {
                    setIsYearAutoOpened(false)
                    handleSelect(VehicleSelectorStep.ENGINE, selection)
                  }}
                  onCancel={cancelCurrentStep}
                  options={selectorOptions.engine}
                  enableSearch
                  disableSearch={currentSelections.model === undefined}
                />
              </Engine>
            </VehicleDropdowns>

            <FirstRowButtons>
              {!editing && (
                <AddButton
                  onClick={handleGo}
                  text="add"
                  size="large"
                  disabled={searchDisabled}
                  loading={false}
                />
              )}

              {!editing && (
                <SaveButton
                  onClick={handleGo}
                  text="Save"
                  disabled={searchDisabled}
                  loading={false}
                />
              )}
            </FirstRowButtons>
          </AddVehicleControls>
        </FirstRow>
        {editing && (
          <EditControls
            onConfirm={handleGo}
            onCancel={onVehicleSelected}
            onClearSelections={handleClearSelections}
            updateDisabled={currentStep !== VehicleSelectorStep.END}
          />
        )}
      </>
    )
  }
)

VehicleSelector.displayName = 'VehicleSelector'

const FirstRow = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
`

const VehicleDropdowns = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 8px;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

const AddVehicleControls = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 8px;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  @media screen and (width <= ${breakpoints.laptopMedium}px) {
    flex-wrap: wrap;
  }
`

const FirstRowButtons = styled.div`
  display: flex;
  justify-content: end;

  @media (width < ${specialBreakpoints.addVehicleButtonWrap}px) {
    width: 100%;
  }
`

const AddButton = styled(Button)`
  margin: auto 0px 0px 0px;

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const SaveButton = styled(Button)`
  margin-top: auto;
  display: none;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: block;
    margin-bottom: 0px;
    width: 100%;
  }
`

const VehicleType = styled.div`
  width: 100%;
  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 332px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 248px;
    max-width: 332px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 224px;
    max-width: 248px;
  }
`

const Year = styled.div`
  width: 100%;
  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 166px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 124px;
    max-width: 166px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 100px;
    max-width: 124px;
  }

  @media screen and (${breakpoints.tabletLarge}px < width <= ${breakpoints.laptop}px) {
    min-width: 100px;
    max-width: 100px;
  }

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    width: 100%;
  }
`

const Make = styled.div`
  width: 100%;
  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 284px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 195px;
    max-width: 284px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 172px;
    max-width: 195px;
  }
`

const Model = styled.div`
  width: 100%;
  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 332px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 248px;
    max-width: 332px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 224px;
    max-width: 248px;
  }
`

const Engine = styled.div`
  width: 100%;
  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 332px;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 248px;
    max-width: 332px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 224px;
    max-width: 248px;
  }
`
