import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { getCxmlDetails } from './cxmlDetailsUtil'

enum ServiceName {
  AESV3 = 'AESV3',
  MYPLACEV3 = 'MyPlaceV3',
  AES_SERVER_V3 = 'aesServerV3',
  CXML = 'cXML',
}

export const getServiceName = (): ServiceName => {
  if (IS_INTEGRATED_VERSION) {
    return EmbeddedCommunicationsManager.isAesServer
      ? ServiceName.AES_SERVER_V3
      : ServiceName.AESV3
  }
  if (getCxmlDetails().cxmlData) {
    return ServiceName.CXML
  }

  return ServiceName.MYPLACEV3
}
