import { KeyboardEvent, ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Autocomplete, Box, FormControl, TextField } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { TextInput, Checkbox, Button } from 'src/ui-components'
import { MutablePreference, useUserStore } from 'src/store/user/UserStore'
import { I18n, Translate } from 'src/i18n'
import { defaultTheme } from 'src/theme'
import { Spinner } from 'react-bootstrap'
import { vehicleWidgetStore } from '../store/VehicleWidgetStore'
import { usePartsCatalog } from 'src/store/partsCatalog/PartsCatalog'
import { useSearchStore } from 'src/store/search/SearchStore'
import { breakpoints } from 'src/theme/breakpoints'

export const LicensePlateSearch = observer((): ReactElement => {
  const history = useHistory()
  const userStore = useUserStore()
  const statePreference = userStore.getStatePreference()
  const catalog = usePartsCatalog()
  const [plate, setPlate] = useState('')
  const [stateSelection, setStateSelection] = useState(statePreference)
  const { getStatesWithCountryId, states, statesLoading } = useSearchStore()

  const stateList = states ?? []
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchByPlate = async () => {
    setIsLoading(true)
    const foundVehicle = await vehicleWidgetStore.lookupVehicle({
      licencePlate: `${stateSelection}:${plate}`,
    })
    setIsLoading(false)
    if (foundVehicle) {
      vehicleWidgetStore.setIsDrawerOpened(false)
      catalog.resetStore()
      history.push('/partsCatalog')
    }
  }

  const submitIfEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchByPlate()
    }
  }

  useEffect(() => {
    getStatesWithCountryId()
  }, [getStatesWithCountryId])

  const isDefaultState = userStore.getStatePreference() === stateSelection

  return (
    <Wrapper>
      <Row>
        <Label>
          <I18n name="state" />
        </Label>
        <StyledFormControl sx={{ border: 0 }}>
          {statesLoading ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner animation="border" />
            </Box>
          ) : (
            <StateWrapper>
              <Autocomplete
                disablePortal
                autoHighlight
                id="state_selector_dropdown"
                disableClearable
                onChange={(_event: unknown, newValue: string | null) =>
                  setStateSelection(newValue)
                }
                value={stateSelection}
                options={stateList.map((o) => o.regionCode)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={Translate('State')} />
                )}
                style={{ width: '100%' }}
              />
            </StateWrapper>
          )}
        </StyledFormControl>
        <MakeDefaultStateMobile>
          <Checkbox
            onClick={() => {
              userStore.updateUserPreferenceAndSyncToCloud(
                MutablePreference.EPEFEATURES_DEFAULTSTATE,
                stateSelection
              )
            }}
            value="makeDefault"
            active={isDefaultState}
            label={Translate('makeThisMyDefaultState')}
          />
        </MakeDefaultStateMobile>

        <Label>
          <I18n name="plateNumber" />
        </Label>
        <InputWrapper>
          <TextInput
            onChange={(text) => {
              setPlate(text)
            }}
            onKeyPress={submitIfEnter}
            value={plate}
            width="100%"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            placeholder="enterAPlateNumber"
            noMargin
            style={{ borderRadius: '0px' }}
          />
        </InputWrapper>

        <SyltedButton
          onClick={handleSearchByPlate}
          loading={isLoading}
          disabled={plate.length < 2}
          text="add"
          size="large"
        />
      </Row>
      <MakeDefaultStateDesktop>
        <Checkbox
          onClick={() => {
            userStore.updateUserPreferenceAndSyncToCloud(
              MutablePreference.EPEFEATURES_DEFAULTSTATE,
              stateSelection
            )
          }}
          value="makeDefault"
          active={isDefaultState}
          label={Translate('makeThisMyDefaultState')}
        />
      </MakeDefaultStateDesktop>
    </Wrapper>
  )
})

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  flex-wrap: wrap;
`

const MakeDefaultState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .checkbox-wrapper {
    span {
      color: ${defaultTheme.colors.disabledButtonText};
    }
  }

  @media screen and (width < ${breakpoints.mobileLarge}px) {
    display: none;
  }
`

const MakeDefaultStateDesktop = styled(MakeDefaultState)`
  @media screen and (width < ${breakpoints.mobileLarge}px) {
    display: none;
  }
`

const MakeDefaultStateMobile = styled(MakeDefaultState)`
  display: none;
  margin-bottom: 24px;
  @media screen and (width < ${breakpoints.mobileLarge}px) {
    display: flex;
    justify-content: start;
    width: 100%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
`

const InputWrapper = styled.div`
  width: 233px;
  margin-right: 6px;

  @media screen and (width < ${breakpoints.mobileLarge}px) {
    width: 100%;
    margin-bottom: 24px;
    margin-right: 0px;
  }
`

const StyledFormControl = styled(FormControl)`
  border: 1px solid ${(p) => p.theme.colors.border};
  width: 97px;
  background-color: #fff;
  height: 50px;
  border-right: 0;
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 0;
  @media screen and (width < ${breakpoints.mobileLarge}px) {
    width: 100%;
    border-right: 1px solid ${(p) => p.theme.colors.border};
    margin-bottom: 10px;
  }
`

const SyltedButton = styled(Button)`
  max-width: 128px;
  flex-grow: 1;
  @media screen and (width < ${breakpoints.mobileXLarge}px) {
    max-width: none;
    margin-right: 0px;
  }
`

const StateWrapper = styled.div`
  @media screen and (width < ${breakpoints.mobileXLarge}px) {
    width: 100%;
  }
`

const Label = styled.div`
  display: none;
  font-weight: 700;
  font-size: 14px;
  @media screen and (width < ${breakpoints.mobileLarge}px) {
    display: block;
    margin-bottom: 3px;
  }
`
