import { Search } from '@mui/icons-material'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useClickOutsideDetector } from 'src/hooks/useClickOutsideDetector'
import SaytServiceProvider from 'src/services/SaytServiceProvider'
import { useUserStore } from 'src/store/user/UserStore'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { SearchFieldProps } from '../interfaces'
import { HistoricalEntriesOverlay } from './HistoricalEntriesOverlay'
import { InterchangeOverlay } from './InterchangeOverlay'
import { SaytOverlay } from './SaytOverlay'

enum Key {
  tab = 'Tab',
}

export const SearchField = (props: SearchFieldProps): ReactElement => {
  const {
    label,
    placeholder,
    onSearch,
    onSelectOption,
    dropdown,
    type,
    textFieldValue = '',
    multiPartInquiry,
  } = props
  const [isTyping, setIsTyping] = useState(false)
  const [enableSearchHistory, setEnableSearchHistory] = useState(false)
  const [fieldValue, setFieldValue] = useState('')
  const [data, setData] = useState([])
  const wrapperRef = useRef(null)
  const wrapperRefSearhHistory = useRef(null)
  const [showHistoryEntries, setShowHistoryEntries] = useState(true)

  useClickOutsideDetector([wrapperRef], isTyping, () => setIsTyping(false))
  useClickOutsideDetector([wrapperRefSearhHistory], enableSearchHistory, () =>
    setEnableSearchHistory(false)
  )
  const user = useUserStore()

  const getOptions = async (typed: string) => {
    return SaytServiceProvider.getSaytOptions(typed, user.getLanguage())
  }

  const searchesExistInLocalStorage = () => {
    const localStorageSearches = JSON.parse(localStorage.getItem('searches'))
    return !!localStorageSearches
  }

  const handleChange = async (e) => {
    setShowHistoryEntries(true)
    if (e.target.value !== '') {
      setEnableSearchHistory(false)
    }
    if (e.target.value === '' && searchesExistInLocalStorage()) {
      setEnableSearchHistory(true)
    }
    setFieldValue(e.target.value)
    try {
      if (type === 'interchange') {
        return
      }
      if (e.target.value !== '') {
        const options = await getOptions(e.target.value)
        setIsTyping(true)

        const filteredOptions =
          type === 'astOnly' ? options.filter((o) => o.astSearch) : options

        setData(filteredOptions)
        return
      }
      setData([])
      setIsTyping(false)
    } catch (_error) {
      // Do nothing, if SAYT fails, the field will still function as a text input for searches
    }
    if (e.target.value !== '') {
      setIsTyping(true)
      return
    }
    setIsTyping(false)
  }

  const handlePerformSearch = () => {
    onSearch(fieldValue, data)
    setIsTyping(false)
    setEnableSearchHistory(false)
    setShowHistoryEntries(false)
  }

  const handleFocus = () => {
    if (searchesExistInLocalStorage()) {
      setEnableSearchHistory(true)
    }

    if (type === 'interchange') {
      setIsTyping(true)
    }

    if (type === 'sayt' && multiPartInquiry) {
      setEnableSearchHistory(true)
    }
  }

  const handleTabNavigationDropdowns = (e) => {
    if (e.key === Key.tab && enableSearchHistory) {
      setEnableSearchHistory(false)
    }
    if (e.key === Key.tab && isTyping) {
      setIsTyping(false)
    }
  }

  useEffect(() => {
    setFieldValue(textFieldValue)
  }, [textFieldValue])

  return (
    <Wrapper>
      <LabelWrapper>
        <Label key={0}>{label}</Label>
      </LabelWrapper>
      <FieldWrapper>
        <Field
          data-testid="input"
          onFocus={handleFocus}
          value={fieldValue}
          onChange={handleChange}
          placeholder={placeholder}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handlePerformSearch()
            }
          }}
          onKeyDown={handleTabNavigationDropdowns}
        />
        <Search
          style={{ marginRight: 20, fontSize: 20, cursor: 'pointer' }}
          onClick={handlePerformSearch}
        />
        {/* If user's typing, the results box opens automatically */}
        {type !== 'interchange' ? (
          <>
            {isTyping && !enableSearchHistory && dropdown && (
              <SaytOverlay
                options={data}
                onSelectOption={(selected) => {
                  onSelectOption(selected)
                  setFieldValue(selected.label)
                  setIsTyping(false)
                }}
                fieldValue={fieldValue}
                wrapperRef={wrapperRef}
              />
            )}

            {showHistoryEntries && enableSearchHistory && dropdown && (
              <HistoricalEntriesOverlay
                wrapperRef={wrapperRefSearhHistory}
                setEnableSearchHistory={setEnableSearchHistory}
                onSearch={(text) => {
                  onSearch(text, data)
                  setFieldValue(text)
                }}
              />
            )}
          </>
        ) : type === 'interchange' ? (
          isTyping && dropdown && <InterchangeOverlay wrapperRef={wrapperRef} />
        ) : null}
      </FieldWrapper>
    </Wrapper>
  )
}
SearchField.defaultProps = {
  dropdown: true,
  sayt: true,
}
const Wrapper = styled.div`
  display: flex;
  flex-shrink: 1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: 100%;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const Label = styled.span`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
  user-select: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    font-size: 16px;
  }
`
const LabelWrapper = styled.div`
  min-width: 105px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #050505;
  border-radius: 3px 0 0 3px;
  padding: 16px 12px;
`
const FieldWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #d3d2cf;
  box-sizing: border-box;
  border-radius: 0 3px 3px 0;
  height: 56px;
  flex-grow: 2;
  flex-wrap: inherit;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`

const Field = styled.input`
  border: none;
  width: 99%;
  height: 100%;
  margin-left: 16px;
  flex-grow: 1;
  overflow: hidden;
  &:focus {
    outline-width: 0;
    outline: none;
  }
  &::placeholder {
    color: #9b9b9b;
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
  }
`
