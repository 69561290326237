/**
 * Convenience class to make accessing env settings in code cleaner and easier
 */

export const IS_INTEGRATED_VERSION =
  import.meta.env.VITE_IS_INTEGRATED_VERSION === 'true'
export const IS_HD_INTERCHANGE_FLOW_ENABLED =
  import.meta.env.VITE_FETAURE_HD_INTERCHANGE_FLOW_ENABLED === 'true'
export const IS_CROSS_REFERENCE_FLOW_ENABLED =
  import.meta.env.VITE_FETAURE_CROSS_REFERENCE_FLOW_ENABLED === 'true'

class ConfigManager {
  private cartNormalMode = true

  private readonly _deploymentEnvironment: DeploymentEnvironment

  private readonly _googleAnalyticsId: string

  private readonly _promotionsEnabled: boolean

  private readonly _resortItemsEnabled: boolean

  private readonly _bannerEnabled: boolean

  private readonly _rmaEnabled: boolean

  private readonly cartVersionStr = '1.1.0' // In case we need to make breaking schema changes.

  private readonly _purolatorEnabled: boolean

  private readonly _ddsEnabled: boolean

  public xTokenApiKey: string

  constructor() {
    this.xTokenApiKey = import.meta.env.VITE_TOKEN_API_KEY
    this._deploymentEnvironment = import.meta.env
      .VITE_MODE as DeploymentEnvironment
    this._googleAnalyticsId = import.meta.env.VITE_GA_ID
    this._promotionsEnabled =
      import.meta.env.VITE_FEATURE_PROMOTIONS_ENABLED === 'true'
    this._bannerEnabled = import.meta.env.VITE_FEATURE_BANNER_ENABLED === 'true'
    this._resortItemsEnabled =
      import.meta.env.VITE_FEATURE_RESORT_ITEMS_ENABLED === 'true'
    this._rmaEnabled = import.meta.env.VITE_FETAURE_RMA_ENABLED === 'true'
    this._purolatorEnabled =
      import.meta.env.VITE_FETAURE_PUROLATOR_ENABLED === 'true'
    this._ddsEnabled = import.meta.env.VITE_FETAURE_DDS_ENABLED === 'true'
  }

  /**
   * Returns 'true' as long as the 'Cart only' mode is not being used.
   *
   * @TODO: Refactor this feature. "Cart Only" mode is the exception, not the norm.
   * Should invert all references to avoid double-negative logic.
   * For now, just renaming to make what is actually meant by this setting slightly more clear since the cart can have other modes as well.
   */
  get isNotCartOnlyMode() {
    return this.cartNormalMode
  }

  get promotionsEnabled() {
    return this._promotionsEnabled
  }

  get resortItemsEnabled() {
    return this._resortItemsEnabled
  }

  get bannerEnabled() {
    return this._bannerEnabled
  }

  get rmaEnabled(): boolean {
    return this._rmaEnabled
  }

  get purolatorEnabled(): boolean {
    return this._purolatorEnabled
  }

  get ddsEnabled(): boolean {
    return this._ddsEnabled
  }

  get deploymentEnvironment(): DeploymentEnvironment {
    return this._deploymentEnvironment
  }

  get googleAnalyticsId(): string {
    return this._googleAnalyticsId
  }

  setCartOnlyMode() {
    this.cartNormalMode = false
  }

  get cartVersion() {
    return this.cartVersionStr
  }
}

export enum DeploymentEnvironment {
  staging = 'staging',
  qa = 'qa',
  production = 'production',
}

export const Config = new ConfigManager()
