import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { NavigationItemsWrapper } from '../CommonMenuItems'
import { MenuItem, IMenuItem } from './MenuItem'
import { featureFlagFilter } from './Filter'

const AstPunchOutMenuItems = observer((): ReactElement => {
  const astMenuItems: IMenuItem[] = [
    { text: 'partsCatalog', path: '/astPartsCatalog' },
    { text: 'orders', path: '/orderHistory' },
    { text: 'quotes', path: '/quotes' },
    { text: 'lists', path: '/lists' },
  ]

  const menuItems = featureFlagFilter(astMenuItems)

  return (
    <NavigationItemsWrapper>
      {menuItems.map((menuItem, idx) => {
        const key = `desktop-menu-item-${idx}`
        return <MenuItem key={key} {...menuItem} />
      })}
    </NavigationItemsWrapper>
  )
})

export default AstPunchOutMenuItems
