import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { I18n } from 'src/i18n'
import styled from 'styled-components'

import { defaultTheme } from 'src/theme'
import { Checkbox } from 'src/ui-components/forms/Checkbox'

import { useListsStore } from 'src/store/lists/ListsStore'

export const MultiSearchTableHeader = observer(
  (props: { id: number }): React.ReactElement => {
    const [checkBoxSelected, setCheckBoxSelected] = useState(false)
    const { selectAllPartsFromAList } = useListsStore()

    useEffect(() => {
      if (checkBoxSelected) {
        selectAllPartsFromAList(true, props.id)
      } else {
        selectAllPartsFromAList(false, props.id)
      }
    }, [checkBoxSelected, selectAllPartsFromAList, props.id])

    return (
      <THead>
        <Row>
          <TCell
            style={{
              width: '60px',
              paddingLeft: '25px',
            }}
          >
            <Checkbox
              value=""
              label=""
              active={checkBoxSelected}
              onClick={() => setCheckBoxSelected(!checkBoxSelected)}
            />
          </TCell>
          <TCell
            style={{
              width: '120px',
            }}
          >
            <TitleContainer>
              <I18n name="brand" />
            </TitleContainer>
          </TCell>
          <TCell
            style={{
              width: '100px',
            }}
          >
            <TitleContainer>
              <I18n name="mfr" />
            </TitleContainer>
          </TCell>
          <TCell style={{ width: '15%' }}>
            <TitleContainer>
              <I18n name="partNum" />
            </TitleContainer>
          </TCell>
          <TCell style={{ width: '90px' }}>
            <TitleContainer>
              <I18n name="qty" />
            </TitleContainer>
          </TCell>
          <TCell style={{ width: '15%' }}>
            <TitleContainer>
              <I18n name="description" />
            </TitleContainer>
          </TCell>
          <TCell style={{ width: '15%' }}>
            <TitleContainer>
              <I18n name="comments" />
            </TitleContainer>
          </TCell>
          <TCell>
            <TitleContainer>
              <I18n name="lastUsed" />
            </TitleContainer>
          </TCell>
          <TCell style={{ width: '90px' }} />
        </Row>
      </THead>
    )
  }
)

const THead = styled.thead`
  background: ${defaultTheme.colors.rowFeaturedBackground};
  border-top: 1px solid ${defaultTheme.colors.borderTable};
  width: 100%;
  min-height: 48px;
`
const TCell = styled.td`
  text-align: left;
`
const Row = styled.tr`
  width: 100%;
  box-shadow: inset 0px -1px 0px ${defaultTheme.colors.greyMedium};
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-family: Roboto;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  padding: 7px;
  color: ${defaultTheme.colors.columnBorder};
`
