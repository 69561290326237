import { debounce } from 'lodash-es'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import HiddenItemsMenu from './HiddenItemsMenu'

interface Props<T> {
  items: T[]
  gap: number
  collapsedHeader?: T
  statusIcon?: (isOpen: boolean) => ReactElement
}

interface UIElementBase {
  width: number
}

type UIElement<T extends object> = T & UIElementBase

const ResponsiveMenu = <T extends ReactElement>(
  props: Props<T>
): ReactElement => {
  const { items, collapsedHeader, gap, statusIcon } = props
  const wrapperRef = useRef<HTMLDivElement>()
  const [showDropdown, setShowDropdown] = useState(true) // Show the dropdown first to get its width, then hide if necessary.
  const [openDropdown, setOpenDropdown] = useState(false)
  const collapedHeaderWidth = useRef<number>(0)
  const rowMenuRef = useRef<HTMLUListElement>()
  const collapsedHeaderRef = useRef<HTMLUListElement>()
  const [visibleItems, setVisibleItems] = useState<T[]>([...items])
  const [hiddenItems, setHiddenItems] = useState<T[]>([])
  const allElements = useRef<UIElement<T>[]>([])

  const collectAllElementsWidth = useCallback(() => {
    const uiElements = Array.from(rowMenuRef?.current?.children || [])

    const elements = uiElements?.map((e, idx) => {
      return {
        ...items[idx]?.props,
        width: e.clientWidth,
      }
    })

    allElements.current = elements || []
    const dropdownWidth = collapsedHeaderRef?.current?.offsetWidth ?? 0
    collapedHeaderWidth.current = dropdownWidth
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResize = useCallback(() => {
    const isElementsWithCollected = allElements?.current?.length
    if (!isElementsWithCollected) collectAllElementsWidth()
    const dropdownWidth = collapedHeaderWidth.current
    const menuWidth = !isElementsWithCollected
      ? wrapperRef.current.clientWidth - dropdownWidth
      : wrapperRef.current.clientWidth

    let usedWidth = 0
    const hidden = []
    const visible = []

    for (let i = 0; i < allElements.current.length; i++) {
      const element = allElements.current[i]
      const requiredWith = usedWidth + element.width + gap
      if (requiredWith < menuWidth) {
        visible.push(items[i])
        usedWidth = requiredWith
      } else {
        const firstHiddenElement = hidden?.length <= 0
        if (firstHiddenElement) {
          // collapsing the last two elements
          hidden.push(items[i])
          hidden.push(items[i - 1])
          visible.pop()
        } else {
          hidden.push(items[i])
        }
      }
    }

    setVisibleItems(visible)
    setHiddenItems(hidden)
    setShowDropdown(collapsedHeader && hidden?.length > 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const resize = debounce(handleResize, 100)
    resize() // Initial call to set things up
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [handleResize])

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown)
  }

  const icon = statusIcon ? statusIcon(openDropdown) : null

  return (
    <Wrapper ref={wrapperRef}>
      <UL ref={rowMenuRef} gap={gap}>
        {visibleItems.map((item, idx) => (
          <LI key={`item_${idx}`}>{item}</LI>
        ))}
      </UL>
      {showDropdown && (
        <UL ref={collapsedHeaderRef} style={{ marginLeft: gap }}>
          <MenuHeader onClick={toggleDropdown}>
            {collapsedHeader} {icon}
          </MenuHeader>
          <HiddenItemsMenu
            open={openDropdown}
            onClose={toggleDropdown}
            parentRef={collapsedHeaderRef}
            items={hiddenItems}
          />
        </UL>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`

interface ULProps {
  gap?: number
}

const UL = styled.ul<ULProps>`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0px;
  gap: ${(p) => p.gap}px;
`

const LI = styled.li``

const MenuHeader = styled.div`
  display: flex;
  align-items: center;
`

export default ResponsiveMenu
