import { ReactElement, useContext, useState, KeyboardEvent } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { SearchStoreContext } from 'src/store/search/SearchStore'
import { Button, TextInput } from 'src/ui-components'
import {
  VehicleWidgetErrorState,
  vehicleWidgetStore,
} from '../store/VehicleWidgetStore'
import VinValidator from '../VinValidator'
import { VinCheckMessage } from './VinCheckMessage'
import { usePartsCatalog } from 'src/store/partsCatalog/PartsCatalog'
import { breakpoints } from 'src/theme/breakpoints'
import { I18n } from 'src/i18n'

export const VinSearch = (): ReactElement => {
  const history = useHistory()
  const searchStore = useContext(SearchStoreContext)
  const catalog = usePartsCatalog()
  const [vin, setVin] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchByVin = async () => {
    if (vin.length !== 17) {
      vehicleWidgetStore.setErrorState(
        VehicleWidgetErrorState.vinDigitCheckFailed
      )
      return
    }
    setIsLoading(true)
    const foundVehicle = await vehicleWidgetStore.lookupVehicle({ vin })
    setIsLoading(false)
    if (foundVehicle) {
      vehicleWidgetStore.setIsDrawerOpened(false)
      catalog.resetStore()
      history.push('/partsCatalog')
    }
    searchStore.setShowVehicleTransition(true)
  }

  const submitIfEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchByVin()
    }
  }

  const sanitizeAndSetVin = (text: string) => {
    const sanitized = VinValidator.sanitize(text)
    setVin(sanitized)
  }

  return (
    <Wrapper>
      <Col>
        <Row>
          <InputWrapper>
            <Label>
              <I18n name="vin" />
            </Label>
            <TextInput
              onChange={sanitizeAndSetVin}
              value={vin}
              onKeyPress={submitIfEnter}
              noMargin
              placeholder="enterVin"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
              width="100%"
            />
          </InputWrapper>

          <VinCheckMessageMobile>
            <VinCheckMessage vin={vin} />
          </VinCheckMessageMobile>

          <SyltedButton
            onClick={handleSearchByVin}
            loading={isLoading}
            disabled={vin.length < 17}
            text="add"
            size="large"
          />
        </Row>
        <VinCheckMessageDesktop>
          <VinCheckMessage vin={vin} />
        </VinCheckMessageDesktop>
      </Col>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  .field-group-content {
    align-items: flex-start;
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 6px;

  @media screen and (width < ${breakpoints.mobileLarge}px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  flex-grow: 1;
  flex-direction: column;
`

const InputWrapper = styled.div`
  width: 100%;
  max-width: 399px;
  @media screen and (width < ${breakpoints.mobileLarge}px) {
    max-width: none;
  }
`

const SyltedButton = styled(Button)`
  max-width: 128px;
  flex-grow: 1;
  @media screen and (width < ${breakpoints.mobileLarge}px) {
    width: 100%;
    max-width: none;
    margin-right: 0px;
  }
`

const VinCheckMessageDesktop = styled.div`
  @media screen and (width < ${breakpoints.mobileLarge}px) {
    display: none;
  }
`

const VinCheckMessageMobile = styled.div`
  display: none;
  @media screen and (width < ${breakpoints.mobileLarge}px) {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-bottom: 24px;
  }
`

const Label = styled.div`
  display: none;
  font-weight: 700;
  font-size: 14px;
  @media screen and (width < ${breakpoints.mobileLarge}px) {
    display: block;
    margin-bottom: 3px;
  }
`
