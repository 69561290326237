import { Config } from 'src/config/ConfigManager'
import {
  ShoppingCartOnlyData,
  ShoppingCartProduct,
} from 'src/store/models/ShoppingCartModels'
import {
  CheckAvailabilityRequest,
  CheckAvailabilityResponse11,
  CheckAvailabilityResponse12,
  CheckAvailabilityResponseProduct,
} from './CheckAvailability'
import { LineGroup } from './LineGroup'
import { PartsBasketCreds } from '../security/interfaces'
import { ProductLocationModel } from 'src/store/models/ProductModel'
import { makeNewCartVehicle } from 'src/store/cart/Utils'
import EmbeddedServiceProvider from 'src/services/EmbeddedServiceProvider'

export class CartOnlyCommunications {
  /**
   * converts an incoming "checkAvailabilityResponse" into a standard shopping cart
   */

  public static availabilityResponseToCart(
    resp: CheckAvailabilityResponse12
  ): ShoppingCartOnlyData {
    const data: ShoppingCartOnlyData = {
      vehicles: [],
      version: Config.cartVersion,
    }
    const groupedLines = new Map<number, LineGroup>()
    for (const line of resp?.lineInfo?.line || []) {
      const lineGroup = groupedLines.get(line.lineNo) ?? new LineGroup()
      lineGroup.addProduct(line)
      groupedLines.set(line.lineNo, lineGroup)
    }
    const newCartVehicle = makeNewCartVehicle({})
    data.vehicles.push(newCartVehicle)
    const firstVehicle = data.vehicles[0]
    groupedLines.forEach((lineGroup) => {
      const product = lineGroup.reduce()
      if (product) {
        firstVehicle.products.push(product)
      }
    })

    return data
  }

  public static convertProduct(
    line: CheckAvailabilityResponseProduct,
    alternateLocations: Array<ProductLocationModel>
  ): ShoppingCartProduct {
    const product: ShoppingCartProduct = {
      activeLocationId: line.locationId,
      allianceProductId: undefined,
      alternateParts: undefined,
      availabilityErrors: [],
      brand: line.brand,
      brandName: line.brand,
      brandUrl: undefined,
      c2cURL: undefined,
      caProp65: undefined,
      catLineCode: undefined,
      description: line.description,
      extendedDescription: undefined,
      fromYear: undefined,
      header: undefined,
      images: undefined,
      info: undefined,
      isGfx: false,
      group: undefined,
      lineCode: line.manufacturerCode,
      lineNo: line.lineNo,
      location: alternateLocations,
      manufacturerName: undefined,
      orderNumber: undefined,
      orderSelections: [
        {
          locationId: line.locationId,
          quantityRequested: Number(line.quantityRequested),
        },
      ],
      partDescriptionId: 0,
      partNumber: line.partNumber,
      perCarQty: 0,
      replacementParts: undefined,
      status: line.status,
      subBrand: '',
      subBrandName: '',
      type: line.type,
    }
    return product
  }

  public static convertLocations = (
    lines: Array<CheckAvailabilityResponseProduct>
  ): Array<ProductLocationModel> => {
    const convertedLocations: Array<ProductLocationModel> = []
    for (const line of lines) {
      const location: ProductLocationModel = {
        locationId: line.locationId,
        seqNo: line.seqNo,
        locType: line.locationStatus,
        called: line.locationDescription,
        unitOfMeasure: line.unitOfMeasure,
        minQty: Number(line.minOrderQty),
        displayMinQty: Number(line.minOrderQty),
        qtyAvailable: Number(line.quantityAvailable),
        list: Number(line.unitListPrice),
        cost: Number(line.unitCostPrice),
        coreList: Number(line.unitListCore),
        coreCost: Number(line.unitCorePrice),
        isSelected: false,
      }
      convertedLocations.push(location)
    }
    return convertedLocations
  }

  public static checkAvailability = async (
    creds: PartsBasketCreds,
    req: CheckAvailabilityRequest
  ): Promise<CheckAvailabilityResponse11 | CheckAvailabilityResponse12> => {
    const partsBasket = JSON.stringify(req)
    const resp = await EmbeddedServiceProvider.checkAvailability(
      creds,
      partsBasket
    )

    return resp.data
  }
}
