import { init, setUser, browserTracingIntegration } from '@sentry/react'
import { SENTRY_DSN } from 'src/config/constants'

// https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
interface ISentryUser {
  id?: string
  username?: string
  email?: string
}

const isSentryEnabled = (): boolean => {
  return (
    SENTRY_DSN &&
    !window.location.origin.includes('localhost') &&
    !window.location.origin.includes('127.0.0.1')
  )
}

const getTraceSampleRate = (): number => {
  if (!isSentryEnabled()) {
    return 0
  }

  if (import.meta.env.VITE_MODE === 'staging') {
    return 0.5
  }

  if (
    import.meta.env.VITE_MODE === 'qa' ||
    import.meta.env.VITE_MODE === 'qa-dr'
  ) {
    return 0.3
  }

  if (
    import.meta.env.VITE_MODE === 'production' ||
    import.meta.env.VITE_MODE === 'production-dr'
  ) {
    return 0
  }

  return 0
}

export const initSentry = (): void => {
  if (isSentryEnabled()) {
    init({
      dsn: SENTRY_DSN,
      environment: import.meta.env.VITE_MODE,
      // https://docs.sentry.io/platforms/javascript/guides/react/tracing/#configure
      integrations: [browserTracingIntegration()],
      // For finer control of sent transactions you can adjust this value, or
      // use tracesSampler
      tracesSampleRate: getTraceSampleRate(),
    })
  }
}

export const addUserToSentry = (user: ISentryUser | null): void => {
  if (isSentryEnabled()) {
    setUser(user)
  }
}
